import { getProductVariationType } from '~/data/product-page-data/serialize-product-header'
import { getCategoriesFromCategoryAttributes } from '~/data/product-page-data/serializer'
import serializeImage from '~/data/serialize-image'
import serializeLink from '~/data/serialize-link'
import serializeShopifyImage from '~/data/serialize-shopify-image'

export async function serializeStoryblokProductData({ locale, data }) {
  const content = data?.content ?? null
  const type = getProductVariationType(data?.content)

  const variantsItems =
    content?.image_variant
      ?.map((item) => {
        return { image: serializeShopifyImage(item) }
      })
      ?.filter((item) => item) ?? null

  const isUniquePiece = content?.unique_piece ?? false
  const isExclusivity = content?.exclusivity ?? false
  const isNew = content?.is_new ?? false
  const firstPublishedAtTime = new Date(
    data?.first_published_at ?? null,
  ).getTime()
  const lastBackInStockTime = new Date(
    content?.shopify_last_back_in_stock_datetime ?? null,
  ).getTime()

  const categories = await getCategoriesFromCategoryAttributes(
    content?.categories,
    locale,
  )

  const algoliaCategoriesFacets =
    categories
      ?.map((cat) => cat?.content?.title ?? null)
      ?.filter((item) => item) ?? []

  const trackingCategories =
    categories?.map((cat) =>
      cat?.content?.tracking_title?.length > 0
        ? cat?.content?.tracking_title
        : cat?.name,
    ) ?? []

  return {
    uuid: data?.uuid ?? null,
    slug: data?.slug ?? null,
    algoliaCategoriesFacets,
    cardImage: serializeImage(content?.card_image) ?? null,
    cardImageHover: serializeImage(content?.card_image_hover) ?? null,
    categories,
    trackingCategories,
    category:
      content?.categories
        ?.map((category) => category?.name)
        ?.filter((item) => item) ?? null,
    variantsItems,
    sustainabilityTags:
      content?.product_sustainability_tags?.map(
        (tag) => tag.content?.title ?? null,
      ) ?? [],
    productStatusTags: {
      isUniquePiece,
      isExclusivity,
      isNew,
      lastBackInStockTime: lastBackInStockTime > 0 ? lastBackInStockTime : null,
      firstPublishedAtTime:
        firstPublishedAtTime > 0 ? firstPublishedAtTime : null,
    },
    customTags:
      content?.custom_tags?.map((content) => content?.text)?.filter(Boolean) ??
      [],
    productDesigner: content?.designer?.content?.title
      ? {
          ...serializeLink(content?.designer, locale),
          children: content?.designer?.content?.title ?? null,
        }
      : null,
    subtitle: content?.subtitle ?? null,
    text: content?.text ?? null,
    link: serializeLink(data, locale),
    items: [],
    variation: content?.[type]?.content?.title ?? null,
    hasStockAlert: content?.has_stock_alert ?? false,
    isSpecialOrder: content?.has_special_order_enabled ?? false,
    isPreOrder: content?.has_pre_order ?? false,
    isAdjusting: content?.is_adjustable ?? false,
    adjustableOffset: content?.adjustable_offset
      ? +content?.adjustable_offset
      : 0,
  }
}

import { PRODUCT_PAGE } from '~/lib/fetch-links'
import Storyblok, { pageSbParams } from '~/lib/storyblok'
import { STORYBLOK_TYPES } from '~/lib/storyblok-types'

import { ProductHeaderProps } from '~/components/UI/ProductHeader'

import addDaysToDate from '~/utils/add-days-to-date'
import { isRTFilled } from '~/utils/check-empty-string'
import i18n from '~/utils/i18n'

import { PRODUCT } from '~/data/dictionary'
import {
  ProductAttribute,
  serializeProductAttribute,
} from '~/data/product-page-data/serialize-product-attribute'
import serializeDate from '~/data/serialize-date'
import serializeTable from '~/data/serialize-table'

export type ProductVariations = {
  items: ProductAttribute[]
  active: ProductAttribute
}

export function getProductVariationType(content) {
  const hasStone = !!content?.product_stone
  const hasMetal = hasStone ? false : !!content?.product_metal
  return hasStone ? 'product_stone' : hasMetal ? 'product_metal' : null
}

async function serializeVariations(data, locale): Promise<ProductVariations> {
  const content = data?.content
  const type = getProductVariationType(content)

  if (!type) {
    return null
  }

  const declinationProductsData = await Storyblok.getAll(`cdn/stories`, {
    starts_with: `${STORYBLOK_TYPES.PRODUCT}/`,
    resolve_relations: PRODUCT_PAGE,
    resolve_links: 'url',
    cv: pageSbParams.cv,
    filter_query: {
      root_product: {
        in: content?.root_product,
      },
    },
    ...(pageSbParams?.instance ?? {}),
  })

  const items =
    (
      declinationProductsData?.map((product) => {
        return {
          ...(serializeProductAttribute(product, locale) ?? {}),
          label: product?.content?.[type]?.[0]?.content?.title ?? null,
        }
      }) ?? null
    )
      ?.filter((item) => item && item.label && item.link)
      ?.sort((a, b) => {
        if (a?.label < b?.label) return -1
        if (a?.label > b?.label) return 1
        return 0
      }) ?? []

  const active = {
    ...(serializeProductAttribute(data, locale) ?? {}),
    label: data?.content?.[type]?.[0]?.content?.title ?? null,
  }

  return {
    items,
    active,
  }
}

export default async function serializeProductHeader({
  locale,
  primaryPageData,
  i18nFallbackFields,
}): Promise<ProductHeaderProps> {
  const content = primaryPageData?.content ?? null

  const productVariations = content?.root_product
    ? (await serializeVariations(primaryPageData, locale)) ?? null
    : null

  const informationSections = [
    ...(isRTFilled(i18nFallbackFields?.productDetails)
      ? [
          {
            headline: i18n.t(PRODUCT.EXPAND_PRODUCT_DETAILS),
            text: i18nFallbackFields?.productDetails,
          },
        ]
      : []),
  ]

  return {
    description: i18nFallbackFields?.productDescription ?? null,
    productVariations:
      productVariations?.items?.length > 0 ? productVariations : null,
    sections:
      informationSections && informationSections?.length > 0
        ? informationSections
        : null,
    sizeGuide: content?.size_guide
      ? {
          table: serializeTable(content?.size_guide?.content?.size_guide_table),
          wysiwyg: content?.size_guide?.content?.wysiwyg ?? null,
        }
      : null,
    preOrder: {
      isActive: content?.has_pre_order ?? false,
      deliveryDate: content?.delay_days_pre_order
        ? serializeDate(
            addDaysToDate(
              new Date(Date.now()).toString(),
              +content?.delay_days_pre_order,
            ),
            locale,
          )
        : null,
    },
    isSpecialOrder: content?.has_special_order_enabled ?? false,
    isStockAlert: content?.has_stock_alert ?? false,
    isAdjusting: content?.is_adjustable ?? false,
    adjustableOffset: content?.adjustable_offset
      ? +content?.adjustable_offset
      : 0,
  }
}

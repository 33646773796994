import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { Link, Spinner } from '@unlikelystudio/react-abstract-components'

import { HeartIcon } from '~/components/Abstracts/Icons'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export const Icons = {
  Heart: 'heart',
} as const

type Icons = typeof Icons[keyof typeof Icons]

const ICONS = {
  [Icons.Heart]: HeartIcon,
}

export type IconCtaProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  className?: string
  iconClassName?: string
  text?: string
  icon?: Icons
  loading?: boolean
  disabled?: boolean
  isActive?: boolean
  ariaLabel?: string
  href?: string
}

function IconCta({
  className,
  iconClassName,
  text,
  icon,
  loading,
  disabled,
  isActive,
  onClick,
  ariaLabel,
  href,
}: IconCtaProps) {
  const Icon = ICONS?.[icon] ?? null

  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
    color: GlobalThemeColors.Black,
  })

  return (
    <Link
      className={cx(className, css.IconCta, { loading, disabled, isActive })}
      aria-label={ariaLabel}
      onClick={onClick}
      {...(href && { href })}>
      {loading ? (
        <Spinner className={cx(css.spinner)} />
      ) : Icon ? (
        <Icon className={cx(css.icon, iconClassName)} />
      ) : (
        <span className={textStyle}>{text}</span>
      )}
    </Link>
  )
}

IconCta.defaultProps = {
  loading: false,
  disabled: false,
}

export default IconCta

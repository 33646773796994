import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { WbProductVariant } from '~/@types/product'
import { GlobalTextPreset } from '~/@types/text-preset'
import { processCartItems } from '~/lib/process-cart-items'

import AddToCartButton from '~/components/Abstracts/AddToCartButton'
import { ProductCardQuickBuyProps } from '~/components/UI/ProductCard/ProductCardQuickBuy'
import css from '~/components/UI/ProductCard/ProductCardQuickBuy/styles.module.scss'

import { useStyle } from '~/providers/StyleProvider'

import { isSizeOrDefaultVariantName } from '~/utils/is-size-or-default-variant-name'
import { getVariantFromSelectedOptions } from '~/utils/variant-helpers'

type VariantsCtaProps = {
  variants: WbProductVariant[]
  defaultVariant: ProductCardQuickBuyProps['defaultVariant']
  name: ProductCardQuickBuyProps['name']
  designer: ProductCardQuickBuyProps['designer']
  image: ProductCardQuickBuyProps['image']
  isPreOrder: ProductCardQuickBuyProps['isPreOrder']
  trackingData: ProductCardQuickBuyProps['trackingData']
  onAddToCart?: () => void
  lang?: string
}

const cx = classnames.bind(css)

export function VariantsCta({
  variants,
  defaultVariant,
  onAddToCart,
  name,
  designer,
  image,
  lang,
  isPreOrder,
  trackingData,
}: VariantsCtaProps) {
  const isAdjusting = variants?.find((variant) => variant?.isVariantAdjusting)
  const ctaStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12GroteskRegular,
  })

  const options =
    variants?.reduce((acc, variant) => {
      variant?.selectedOptions?.forEach((option) => {
        if (!acc[option.name]) {
          acc[option.name] = []
        }

        const selectedOptions = [
          ...(defaultVariant?.selectedOptions?.filter?.(
            (selectedOption) => selectedOption.name !== option.name,
          ) ?? []),
          option,
        ]
        const variant = getVariantFromSelectedOptions(selectedOptions, variants)

        if (
          variant &&
          !acc[option.name].find(
            (v: WbProductVariant) =>
              v.id === variant.id &&
              (isAdjusting ? v.title === variant.title : true),
          )
        ) {
          acc[option.name].push({
            ...variant,
            title: option.value,
          })
        }
      })
      return acc
    }, {}) ?? {}

  const variantKeys = Object.keys(options)

  const variantsToDisplay = (): VariantsCtaProps['variants'] =>
    variants?.length >= 1
      ? variantKeys
          ?.map((key) => {
            const isSize = isSizeOrDefaultVariantName(key)
            const selectVariants = options[key]

            return isSize
              ? selectVariants?.map((variant) => ({
                  ...variant,
                  title: `${variant?.title}${
                    isSize && variant?.isVariantAdjusting ? `*` : ``
                  }`,
                }))
              : null
          })
          ?.filter(Boolean)
      : []

  return (
    <div className={css.variantsCta}>
      {variantsToDisplay()
        ?.flat()
        ?.map((variant, i) => {
          if (!variant) return null

          const cartItems = processCartItems({
            merchandiseId: variant?.id,
            quantity: 1,
            attributes: {
              isUniqueSize: false,
              selectedSize: variant?.title ?? null,
              image: image?.data?.src ?? null,
              name,
              designer,
              reference: variant?.sku ?? null,
              lang,
              isAdjusting: variant?.isVariantAdjusting ?? null,
              trackingData:
                JSON.stringify(
                  {
                    ...trackingData,
                    sku: variant?.sku,
                    variant: variant?.title,
                  },
                  (key, value) => {
                    return typeof value === 'string'
                      ? encodeURI(value)?.replace(/'/g, '`')
                      : value
                  },
                ) ?? '',
            },
          })

          const preOrder = isPreOrder && variant?.currentlyNotInStock

          return !preOrder && variant?.availableForSale ? (
            <AddToCartButton
              theme={GlobalThemeColors.White}
              CtaProps={{
                withBackground: true,
                withHover: true,
                spinnerClassName: css.spinner,
              }}
              key={`${variant?.id}-${i}-${variant?.title}`}
              onClick={onAddToCart}
              cartItems={cartItems}
              className={cx(css.variantQuickBuy, ctaStyle, { preOrder })}>
              {variant?.title}
            </AddToCartButton>
          ) : null
        })}
    </div>
  )
}

import { ISbStoryParams } from 'storyblok-js-client'
import { STORYBLOK_PUBLIC_RECENTLY_VIEWED_KEY } from '~/lib/constants'
import { PRODUCT_PAGE } from '~/lib/fetch-links'
import Storyblok, { pageSbParams } from '~/lib/storyblok'

import {
  CARD_IMAGE_LOADER,
  CardImageLoader,
  ProductCardProps,
} from '~/components/UI/ProductCard/types'

import { getBase64IDFromAdminGID } from '~/utils/shopify-ids'

import serializeProduct, {
  PRODUCT_MEDIA_IMAGE,
  ShopifyMediaItem,
} from '~/data/serialize-product'

export async function fetchProductCardsByHandles(
  uids: string,
  locale: string,
  perPage: number,
): Promise<ProductCardProps[]> {
  if (!STORYBLOK_PUBLIC_RECENTLY_VIEWED_KEY) {
    return
  }

  const products =
    (
      await Promise.all([
        Storyblok.get(`cdn/stories`, {
          starts_with: 'product_page/',
          by_slugs: uids,
          resolve_links: 'url',
          resolve_relations: PRODUCT_PAGE,
          per_page: perPage ?? 20,
          cv: pageSbParams.cv,
          ...(pageSbParams?.instance ?? {}),
        }),
      ])
    )?.[0]?.data?.stories ?? []

  return (
    (await Promise.all(
      products?.map((product) => serializeProductCard(product, locale)) ?? null,
    )) ?? []
  )
}

export function serializeStoryblokCardData(
  data,
  locale: string,
  withVariants?: boolean,
): ProductCardProps {
  // We now get all media items not only images
  // So we display images from media, but keep a fallback with data?.items for product with old serialization
  const mediaImages =
    data?.mediaItems?.filter(
      (media: ShopifyMediaItem) => media?.type === PRODUCT_MEDIA_IMAGE,
    ) ?? null

  const shopifyImages = mediaImages ?? data?.items ?? null

  const hasEnoughImages = shopifyImages?.length > 1
  const firstImage = shopifyImages?.[0]?.image ?? null

  return {
    shopifyId: data?.shopifyId ?? null,
    link: data?.link ?? null,
    name: data?.productName ?? null,
    trackingData: data?.trackingData ?? null,
    designer: data?.productDesigner?.children ?? null,
    image:
      firstImage || data?.cardImage
        ? {
            loaderType: data?.cardImage
              ? CARD_IMAGE_LOADER.STORYBLOK
              : CARD_IMAGE_LOADER.SHOPIFY,
            data: data?.cardImage ? data?.cardImage : firstImage ?? null,
          }
        : null,
    imageHover:
      data?.cardImageHover || shopifyImages?.[1]
        ? {
            loaderType: data?.cardImageHover
              ? CARD_IMAGE_LOADER.STORYBLOK
              : CARD_IMAGE_LOADER.SHOPIFY,
            data: data?.cardImageHover
              ? data?.cardImageHover
              : hasEnoughImages
              ? shopifyImages?.[1]?.image ?? null
              : null,
          }
        : null,
    breathImages:
      data?.items?.[0] || data?.items?.[data?.items?.length - 1]?.image
        ? [
            data?.items?.[0]?.image ?? null,
            data?.items?.[data?.items?.length - 1]?.image ??
              data?.items?.[0]?.image ??
              null,
          ]
        : [],
    label: data?.productDesigner?.children ?? null,
    compareAtPrice: data?.compareAtPrice ?? null,
    price: data?.price ?? null,
    unformattedPrice: data?.unformattedPrice ?? null,
    sustainabilityTags: data?.sustainabilityTags ?? null,
    productStatusTags: data?.productStatusTags ?? null,
    customTags: data?.customTags ?? [],
    ...(withVariants && {
      variants: data?.variants ?? [],
      variation: data?.variation ?? null,
    }),
    isPreOrder: data?.isPreOrder ?? null,
    hasStockAlert: data?.content?.has_stock_alert ?? false,
    isSpecialOrder: data?.content?.has_special_order_enabled ?? false,
    variants: data?.variants ?? null,
    isAdjusting: data?.isAdjusting ?? false,
    adjustableOffset: data?.adjustableOffset ?? 0,
  }
}

export default async function serializeProductCard(
  item,
  locale,
  withVariants?: boolean,
): Promise<ProductCardProps> {
  // Serialize Storyblok data that will be used to display the product card
  const serializedProductCardContent =
    (await serializeProduct({
      locale,
      data: item,
    })) ?? null

  if (!serializedProductCardContent) {
    return null
  }

  const cardData = serializeStoryblokCardData(
    serializedProductCardContent,
    locale,
    withVariants,
  )

  return {
    ...cardData,
    wishlistDetails: {
      inList: true,
      uuid: item?.uuid ?? null,
      variantId: getBase64IDFromAdminGID(
        serializedProductCardContent?.defaultVariant?.id,
      ),
    },
  }
}

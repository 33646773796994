import classnames from 'classnames/bind'

import { ProductStatusTags } from '~/components/UI/ProductCard/types'
import ProductTag from '~/components/UI/ProductTag'

import useProductStatusTagsValues from '~/hooks/useProductStatusTagsValues'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ProductCardTagsProps = {
  className?: string
  productStatusTags?: ProductStatusTags
  sustainabilityTags?: string[]
  customTags?: string[]
}

export const PRODUCT_STATUSES = {
  isExclusivity: 'EXCLUSIVITY',
  isUniquePiece: 'UNIQUE_PIECE',
  isNew: 'NEW',
  isBackInstock: 'BACK_IN_STOCK',
}

function ProductCardTags({
  className,
  productStatusTags,
  sustainabilityTags,
  customTags,
}: ProductCardTagsProps) {
  const formattedStatusTags = useProductStatusTagsValues(productStatusTags)
  const hasStatusTags =
    formattedStatusTags?.length > 0 || customTags?.length > 0
  const hasSustainabilityTags = sustainabilityTags?.length > 0

  return hasStatusTags || hasSustainabilityTags ? (
    <div className={cx(css.ProductCardTags, className)}>
      {hasStatusTags && (
        <div className={css.statusTags}>
          {formattedStatusTags?.map((value, index) => (
            <ProductTag key={`status_tag_${index}`} className={cx(css.tag)}>
              {value}
            </ProductTag>
          ))}
          {customTags?.map((value, index) => (
            <ProductTag
              key={`custom_status_tag_${index}`}
              className={cx(css.tag)}>
              {value}
            </ProductTag>
          ))}
        </div>
      )}
      {hasSustainabilityTags && (
        <div className={cx(css.sustainabilityTags)}>
          {sustainabilityTags?.map((tag, index) => (
            <ProductTag
              key={`sustainability_tag_${index}`}
              className={cx(css.tag)}>
              {tag}
            </ProductTag>
          ))}
        </div>
      )}
    </div>
  ) : null
}

ProductCardTags.defaultProps = {}

export default ProductCardTags

import {
  BACK_IN_STOCK_PRODUCT_DURATION,
  NEW_PRODUCT_DURATION,
} from '~/lib/constants'

export function msToDays(timestamp) {
  return timestamp / 1000 / 60 / 60 / 24
}

export default function getProductTimedStatus(
  firstPublishedAtTime,
  lastBackInStockTime,
) {
  const dateNowTime = new Date(new Date().toISOString()).getTime()
  const isBackInstock = lastBackInStockTime
    ? msToDays(dateNowTime - lastBackInStockTime) <
      BACK_IN_STOCK_PRODUCT_DURATION
    : false
  const isNew = firstPublishedAtTime
    ? msToDays(dateNowTime - firstPublishedAtTime) < NEW_PRODUCT_DURATION
    : false

  return { isBackInstock, isNew }
}

import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ProductTagProps = {
  className?: string
  children?: string
  borderColor?: GlobalThemeColors
}

function ProductTag({ className, children, borderColor }: ProductTagProps) {
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Label10_12Grotesk,
    borderColor: borderColor ?? GlobalThemeColors.Black,
  })
  return (
    <div className={cx(css.ProductTag, className, textStyle)}>{children}</div>
  )
}

ProductTag.defaultProps = {}

export default ProductTag

import { DEFAULT_LANG, SHOPIFY_METAFIELDS_KEYS } from '~/lib/constants'
import { getMetafieldValue } from '~/lib/shopify/metafields/helpers'

import { UnlikelyProduct } from '@unlikelystudio/commerce-connector'

import { RichTextBlock } from '~/components/Abstracts/RichText'
import { asText } from '~/components/Abstracts/Storyblok'

import { isRTFilled } from '~/utils/check-empty-string'
import { getLang } from '~/utils/locales'

import { asComplexRichText, asRichText } from '~/data/storyblok/texts'

export type FallbackI18nFieldsData = {
  productName?: string
  productDetails?: RichTextBlock
  productDescription?: string
}

export function serializeFallbackI18nFields({
  shopifyProduct,
  rawStoryblokData,
  locale,
}: {
  shopifyProduct: UnlikelyProduct
  rawStoryblokData: any
  locale: string
}): FallbackI18nFieldsData {
  const lang = getLang(locale)
  let productName = null
  let productDetails = null
  let productDescription = null

  if (!shopifyProduct) {
    return null
  }

  // Getting the current Storyblok fields values
  const storyblokTitle =
    (rawStoryblokData?.content?.name?.length > 0
      ? rawStoryblokData?.content?.name
      : null) ?? null
  const storyblokDetails =
    (isRTFilled(rawStoryblokData?.content?.product_details_wysiwyg)
      ? rawStoryblokData?.content?.product_details_wysiwyg
      : null) ?? null
  const storyblokDescription =
    rawStoryblokData?.content?.product_description ?? null

  // Data from the Shopify product
  const shopifyTitles = {
    en: getMetafieldValue(
      SHOPIFY_METAFIELDS_KEYS.TITLE_EN,
      shopifyProduct?.metafields,
    ),
    fr: shopifyProduct?.title,
  }

  const shopifyDetails = {
    en: getMetafieldValue(
      SHOPIFY_METAFIELDS_KEYS.DETAILS_EN,
      shopifyProduct?.metafields,
    ),
    fr: getMetafieldValue(
      SHOPIFY_METAFIELDS_KEYS.DETAILS_FR,
      shopifyProduct?.metafields,
    ),
  }

  const shopifyDescriptions = {
    en: getMetafieldValue(
      SHOPIFY_METAFIELDS_KEYS.DESCRIPTION_EN,
      shopifyProduct?.metafields,
    ),
    fr: shopifyProduct?.description,
  }

  // Starting to override the titles and stuff
  if (!storyblokTitle) {
    if (lang === DEFAULT_LANG) {
      productName = shopifyTitles?.['en'] ?? shopifyTitles?.['fr']
    } else {
      productName = shopifyTitles?.['fr']
    }
  } else {
    productName = storyblokTitle
  }

  // Now checking and replacing all the fields
  if (!storyblokDetails) {
    if (lang === DEFAULT_LANG) {
      productDetails =
        (shopifyDetails?.['en']
          ? asComplexRichText({
              items: shopifyDetails?.['en']
                ?.split('\n')
                ?.map((splittedText) => {
                  return {
                    type: 'paragraph',
                    texts: [splittedText],
                  }
                }),
            })
          : null) ?? null
    } else {
      productDetails =
        (shopifyDetails?.['fr']
          ? asComplexRichText({
              items: shopifyDetails?.['fr']
                ?.split('\n')
                ?.map((splittedText) => {
                  return {
                    type: 'paragraph',
                    texts: [splittedText],
                  }
                }),
            })
          : null) ?? null
    }
  } else {
    productDetails = storyblokDetails
  }

  const parsedDescription = storyblokDescription
    ? asText(asRichText(storyblokDescription))
    : null

  if (!parsedDescription) {
    if (lang === DEFAULT_LANG) {
      productDescription = shopifyDescriptions?.['en'] ?? null
    } else {
      productDescription = shopifyDescriptions?.['fr'] ?? null
    }
  } else {
    productDescription = storyblokDescription
  }

  return { productName, productDetails, productDescription }
}

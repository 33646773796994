import classnames from 'classnames/bind'
import { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { CartItemProps } from '~/@types/cart'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { TRACKING_EVENTS } from '~/lib/constants'

import { useUpdateCartLines } from '@unlikelystudio/react-ecommerce-hooks'

import WrapperWithLink from '~/components/Abstracts/WrapperWithLink'
import {
  ProductBottomRowProps,
  ProductDeleteConfirmationProps,
  ProductImageProps,
  ProductInfoProps,
  ProductRemoveCtaProps,
  ProductTopRowProps,
} from '~/components/UI/CartContent/CartItem/types'
import Image from '~/components/UI/Image'
import InlineCta from '~/components/UI/InlineCta'
import SquaredCta from '~/components/UI/SquaredCta'

import { usePanel } from '~/providers/PanelProvider'
import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useAlerts from '~/hooks/useAlerts'
import { useINPTrick } from '~/hooks/useINPTrick'

import { CART, CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'lg', ratio: 10 / 60 }, { ratio: 8 / 30 }]

export function ProductImage({ className, src }: ProductImageProps) {
  return (
    src && (
      <div className={cx(className)}>
        <Image
          layout="fill"
          objectFit="cover"
          objectPosition="bottom"
          ratio={css.ratio}
          sizesFromBreakpoints={IMAGE_SIZES}
          src={src}
          asPlaceholder
        />
      </div>
    )
  )
}

function ProductRemoveCta({ onDelete }: ProductRemoveCtaProps) {
  const t = useTranslate()
  const ctaStyle = useStyle({
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <InlineCta
      className={cx(css.removeCta, ctaStyle)}
      onClick={onDelete}
      textPreset={GlobalTextPreset.Cta10GroteskRegular}
      theme={GlobalThemeColors.Black}
      {...{ children: t(CTA.REMOVE_FROM_CART) }}></InlineCta>
  )
}

function ProductTopRow({
  title,
  href,
  price,
  designer,
  quantity,
  removeCurrent,
}: ProductTopRowProps) {
  const productPriceStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Grotesk,
  })
  const productLabelStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Grotesk,
  })

  const designerStyle = useStyle({
    textPreset: GlobalTextPreset.Text12_20SangBleu,
    color: GlobalThemeColors.Black,
  })

  return (
    <div className={cx(css.productTopRow)}>
      {price && quantity && (
        <p className={cx(css.price, productPriceStyle)}>
          {quantity}x {price}
        </p>
      )}
      <WrapperWithLink
        href={href}
        className={css.productLink}
        onClick={removeCurrent}>
        {designer && (
          <div className={cx(css.itemDesigner, designerStyle)}>{designer}</div>
        )}
        {title && (
          <div className={cx(css.itemTitle, productLabelStyle)}>{title}</div>
        )}
      </WrapperWithLink>
    </div>
  )
}

function ProductBottomRow({
  handleDelete,
  isRemoving,
  isMini,
  ...rest
}: ProductBottomRowProps) {
  return (
    <div className={cx(css.productBottomRow)}>
      <ProductInfo className={css.productInfos} {...rest} />
      {!isRemoving && !isMini && <ProductRemoveCta onDelete={handleDelete} />}
    </div>
  )
}

function ProductInfo({
  className,
  selectedSize,
  isAdjusting,
  isPreOrder,
  isUniqueSize,
}: ProductInfoProps) {
  const t = useTranslate()

  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Label10Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const sizeStyle = useStyle({
    textPreset: GlobalTextPreset.Text12GroteskRegular,
  })

  return (
    <div className={cx(className, css.productInfo)}>
      {isPreOrder && (
        <p className={cx(css.label, labelStyle)}>{t(CART.PRE_ORDER)}</p>
      )}
      {isUniqueSize && (
        <p className={cx(css.label, labelStyle)}>{t(CART.UNIQUE_SIZE)}</p>
      )}
      {isAdjusting && (
        <p className={cx(css.label, labelStyle)}>{t(CART.ADJUSTED_SIZE)}</p>
      )}
      {selectedSize && (
        <p className={cx(css.label, css.sizeLabel, labelStyle)}>
          {t(CART.NORMAL_SIZE)}
          &nbsp;:
          <span className={cx(css.selectedSize, sizeStyle)}>
            {selectedSize}
          </span>
        </p>
      )}
    </div>
  )
}

function ProductDeleteConfirmation({
  onConfirm,
  onCancel,
}: ProductDeleteConfirmationProps) {
  const t = useTranslate()
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Label10Grotesk,
  })

  const { hasInteracted, onInteract } = useINPTrick()

  return (
    <div className={css.deleteConfirmation}>
      <p className={cx(css.deleteTitle, titleStyle)}>{t(CART.REMOVE_TITLE)}</p>
      <div className={css.deleteButtons}>
        <SquaredCta
          className={cx(css.cta, hasInteracted && css.hasInteracted)}
          theme={GlobalThemeColors.Black}
          withBorder
          withBackground={false}
          onClick={() => onInteract(onCancel)}>
          {t(CTA.CANCEL)}
        </SquaredCta>
        <SquaredCta
          className={cx(css.cta, hasInteracted && css.hasInteracted)}
          theme={GlobalThemeColors.Black}
          onClick={() => onInteract(onConfirm)}
          withBackground
          withBorder={false}>
          {t(CTA.REMOVE)}
        </SquaredCta>
      </div>
    </div>
  )
}

export default function CartItem({
  className,
  title,
  designer,
  imageSrc,
  size,
  price,
  reference,
  quantity,
  isAdjusting,
  isPreOrder,
  isUniqueSize,
  selectedSize,
  attributes,
  priceAmount,
  href,
  id,
  isMini,
  isMutating,
  setIsMutating,
}: CartItemProps) {
  const { removeCurrent } = usePanel()
  const [isRemoving, setIsRemoving] = useState(false)
  const referenceStyle = useStyle({
    textPreset: GlobalTextPreset.Text12GroteskRegular,
  })

  const triggerAlert = useAlerts()
  const tracker = useTracker()

  const triggerEvent = (event: string) => {
    tracker.emit<typeof event>(event, {
      item: JSON.parse(attributes?._trackingData ?? '') ?? {},
    })
  }

  const { mutate: onUpdateMutation } = useUpdateCartLines({
    onError: () => {
      setIsMutating(false)
      triggerAlert()
    },
    onSuccess: () => {
      triggerEvent(TRACKING_EVENTS.PRODUCT_REMOVE_FROM_CART)
      setIsMutating(false)
    },
  })

  const onDelete = () => {
    if (id) {
      setIsMutating(true)
      onUpdateMutation([
        {
          id,
          quantity: 0,
        },
      ])
    }
  }

  return (
    <div className={cx(css.CartItem, className, { isMutating, isMini })}>
      <div className={css.productInfos}>
        <WrapperWithLink
          className={cx(css.imageWrapper)}
          href={href}
          onClick={removeCurrent}>
          {imageSrc && <ProductImage src={imageSrc} />}
          {reference && !isMini && (
            <p className={cx(css.reference, referenceStyle)}>{reference}</p>
          )}
        </WrapperWithLink>
        <div className={css.productInfosRows}>
          <ProductTopRow
            title={title}
            designer={designer}
            href={href}
            price={price}
            quantity={isMini ? 1 : quantity}
            removeCurrent={removeCurrent}
          />
          <ProductBottomRow
            isMini={isMini}
            isAdjusting={isAdjusting}
            isPreOrder={isPreOrder}
            isUniqueSize={isUniqueSize}
            selectedSize={selectedSize}
            handleDelete={() => {
              setIsRemoving(true)
            }}
            isRemoving={isRemoving}
          />
          {isRemoving && (
            <ProductDeleteConfirmation
              onConfirm={onDelete}
              onCancel={() => {
                setIsRemoving(false)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

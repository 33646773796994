import { UnlikelyMetafields } from '@unlikelystudio/commerce-connector'

/**
 * The function `getMetafieldValue` retrieves the value of a specific metafield using its key from a
 * given set of metafields.
 * @param {string} key - The `key` parameter is a string that represents the specific key of the
 * metafield whose value you want to retrieve.
 * @param {UnlikelyMetafields} metafields - Metafields are additional pieces of information that can be
 * associated with a particular object in a system. In this context, `metafields` likely refer to a
 * collection of metadata fields that contain various key-value pairs related to a specific object or
 * entity. The `UnlikelyMetafields` type suggests that these
 * @returns The function `getMetafieldValue` is returning the value of the metafield with the specified
 * key from the `metafields` object. If the metafield with the given key exists, it will return its
 * value. If the metafield does not exist or if the value is `undefined`, it will return `null`.
 */
export function getMetafieldValue(key: string, metafields: UnlikelyMetafields) {
  return metafields?.[key]?.value ?? null
}

import { UseQueryResult } from '@tanstack/react-query'
import {
  SHOPIFY_PRODUCT_METAFIELDS_KEYS,
  SHOPIFY_VARIANT_METAFIELDS_KEYS,
} from '~/lib/shopify/metafields'
import { getSfProduct } from '~/lib/shopify/product/product-by-id'

import { GID, UnlikelyProduct } from '@unlikelystudio/commerce-connector'
import { useShopQuery } from '@unlikelystudio/react-ecommerce-hooks'

export function useGetProduct({
  id,
  locale,
  includeTags = false,
  includeQuantityAvailable = false,
  queryOptions = {},
}: {
  id: GID
  locale: string
  includeTags?: boolean
  includeQuantityAvailable?: boolean
  queryOptions?: Record<any, any>
}): UseQueryResult<UnlikelyProduct> {
  return useShopQuery({
    key: 'get-product',
    queryOptions,
    variables: {
      id,
      includeTags,
      includeQuantityAvailable,
    },
    queryFn: async () => {
      return await getSfProduct({
        id,
        includeTags,
        includeQuantityAvailable,
        variantsFirst: 100,
        storeAvailabilityFirst: 100,
        includePrices: true,
        mediaFirst: 100,
        imagesFirst: 100,
        includeDescriptionHtml: true,
        includeFeaturedImage: true,
        includeSeo: true,
        includeSelectedOptions: true,
        metafieldKeys: SHOPIFY_PRODUCT_METAFIELDS_KEYS,
        metafieldVariantsKeys: SHOPIFY_VARIANT_METAFIELDS_KEYS,
        locale,
      })
    },
  })
}

import StoryblokClient, { ISbStoriesParams } from 'storyblok-js-client'
import config from '~/../config/storyblok.json'
import { DEFAULT_LANG } from '~/lib/constants'
import { getRevalidateTimeFromEnv } from '~/lib/revalidate-next'

const storyblokInstance = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
})

export default storyblokInstance

export const getStoryblokVersion = () => {
  const MS_REVALIDATE = getRevalidateTimeFromEnv() * 1000
  const now = Date.now()
  const processed = now - MS_REVALIDATE
  const version = processed > config.timestamp ? now : config.version

  return version
}

class PageSbParams {
  private _version: ISbStoriesParams['version']
  private _cv: ISbStoriesParams['cv']
  private _lang: ISbStoriesParams['language']

  constructor() {
    this.instance = { version: 'published', language: DEFAULT_LANG }
  }

  get version(): ISbStoriesParams['version'] {
    return this._version
  }

  set version(value: ISbStoriesParams['version']) {
    this._version = value
  }

  get cv(): ISbStoriesParams['cv'] {
    return getStoryblokVersion()
  }

  get language(): ISbStoriesParams['language'] {
    return this._lang
  }

  set language(value: ISbStoriesParams['language']) {
    this._lang = value
  }

  get instance(): ISbStoriesParams {
    return {
      version: this.version,
      cv: this.cv,
      language: this.language,
      resolve_level: 1,
    }
  }

  set instance({ version, language }: Omit<ISbStoriesParams, 'cv'>) {
    this.version = version
    this.language = language
  }
}

export const pageSbParams = new PageSbParams()

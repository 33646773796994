import { WbMoney, WbProductVariant } from '~/@types/product'

import { GID, UnlikelyProduct } from '@unlikelystudio/commerce-connector'

import { ImageVideoProps } from '~/components/UI/ImageVideo'

import { ShopifyMediaItem } from '~/data/serialize-product'
import { serializeShopifyProductData } from '~/data/serialize-shopify-product-data'

export type StoryblokShopifyData = {
  shopifyId?: GID
  reference?: string
  unformattedPrice?: WbMoney
  compareAtPrice?: string
  price?: string
  variants?: WbProductVariant[]
  defaultVariant?: WbProductVariant
  isAvailable?: boolean
  isNew?: boolean
  items?: ImageVideoProps[]
  mediaItems?: ShopifyMediaItem[]
}

export function serializeStoryblokShopifyData({
  shopifyProduct,
  storyblokContent,
  locale,
}: {
  shopifyProduct: UnlikelyProduct
  storyblokContent?: any
  locale: string
}): StoryblokShopifyData {
  return serializeShopifyProductData({
    isAdjustable: storyblokContent?.is_adjustable,
    adjustableOffsetValue: storyblokContent?.adjustable_offset
      ? +storyblokContent?.adjustable_offset
      : 0,
    productVariants: shopifyProduct?.variants,
    productImages: shopifyProduct?.images,
    media: shopifyProduct?.media ?? null,
    shopifyProductId: shopifyProduct?.id,
    locale,
  })
}

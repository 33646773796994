import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import { WbProductVariant } from '~/@types/product'
import { OriginType, TRACKING_EVENTS } from '~/lib/constants'
import { processCartItems } from '~/lib/process-cart-items'

import { UnlikelyProduct } from '@unlikelystudio/commerce-connector'
import { LinkProps } from '@unlikelystudio/react-abstract-components'

import { ErrorBoundary } from '~/components/Abstracts/ErrorBoundary'
import { getLangFromLocaleString } from '~/components/Popins/MarketsPopin'
import { ProductCardQuickBuyContent } from '~/components/UI/ProductCard/ProductCardQuickBuy/Content'
import { Icon } from '~/components/UI/ProductCard/ProductCardQuickBuy/Icon'
import { Loader } from '~/components/UI/ProductCard/ProductCardQuickBuy/Loader'
import { OutOfStockButton } from '~/components/UI/ProductCard/ProductCardQuickBuy/OutOfStockButton'
import { PreOrderButton } from '~/components/UI/ProductCard/ProductCardQuickBuy/PreOrderButton'
import { CardImage, ProductCardProps } from '~/components/UI/ProductCard/types'

import { usePopIn } from '~/providers/PopInProvider'
import { useTracker } from '~/providers/TrackerProvider'

import { ProductTrackingData } from '~/hooks/tracking/useTrackProductCardsList'
import { useGetProduct } from '~/hooks/useGetProduct'
import useLocale from '~/hooks/useLocale'

import { CTA } from '~/data/dictionary'
import { serializeShopifyProductData } from '~/data/serialize-shopify-product-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ProductCardQuickBuyProps = {
  className?: string
  isUniqueVariant?: boolean
  ctaDisableIndex?: number
  defaultVariant?: WbProductVariant
  name?: string
  image?: CardImage
  trackingData?: ProductTrackingData
  display?: boolean
  isLoading?: boolean
  wasHovered: boolean
  hasStockAlert?: boolean
  isSpecialOrder?: boolean
  link?: LinkProps
} & Pick<
  ProductCardProps,
  | 'shopifyId'
  | 'isPreOrder'
  | 'designer'
  | 'variants'
  | 'isAdjusting'
  | 'adjustableOffset'
>

export type DisabledButtonProps = {
  link?: LinkProps
  className?: string
}

function ProductCardQuickBuy({
  className,
  variants: unprocessedVariants,
  name,
  image,
  designer,
  isPreOrder,
  trackingData,
  display,
  shopifyId,
  isAdjusting,
  adjustableOffset,
  hasStockAlert,
  isSpecialOrder,
  link,
}: ProductCardQuickBuyProps) {
  const locale = useLocale()

  const { data, isLoading } = useGetProduct({
    id: shopifyId as UnlikelyProduct['id'],
    includeQuantityAvailable: true,
    includeTags: false,
    locale,
  })

  const [displayQuickBuy, setDisplayQuickBuy] = useState(display)
  const tracker = useTracker()

  const lang = getLangFromLocaleString(locale)
  const { removeCurrent } = usePopIn()

  const variants = useMemo(() => {
    if (!data) return unprocessedVariants

    const productData = serializeShopifyProductData({
      isAdjustable: isAdjusting,
      adjustableOffsetValue: adjustableOffset,
      productVariants: data?.variants,
      productImages: [],
      shopifyProductId: shopifyId,
      media: null,
      locale,
    })

    return productData?.variants
  }, [data])

  useEffect(() => {
    setDisplayQuickBuy(display)
  }, [display])

  const variantsAvailableToSell = variants?.filter(
    (variant) =>
      variant?.availableForSale || (variant?.currentlyNotInStock && isPreOrder),
  )

  const isAvailableForSale = variantsAvailableToSell?.length > 0
  const isUniqueVariant = variantsAvailableToSell?.length === 1

  //Get the cheapest variant
  const defaultVariant = variantsAvailableToSell?.sort((a, b) => {
    return a.unformattedPrice?.amount - b.unformattedPrice?.amount
  })?.[0]

  const triggerEvent = (event: string) => {
    tracker.emit<typeof event>(event, {
      origin: OriginType.DETAIL_VIEW,
      item: trackingData,
    })
  }

  const onAddToCart = () => {
    removeCurrent()
    triggerEvent(TRACKING_EVENTS.PRODUCT_ADD_TO_CART)
  }

  const cartItems = processCartItems({
    merchandiseId: variantsAvailableToSell?.[0]?.id,
    quantity: 1,
    attributes: {
      name,
      designer,
      reference: variantsAvailableToSell?.[0]?.sku ?? null,
      lang,
      image: image?.data?.src ?? null,
      selectedSize: variantsAvailableToSell?.[0]?.title ?? null,
      isUniqueSize: variants?.length === 1,
      trackingData:
        JSON.stringify(trackingData, (key, value) => {
          return typeof value === 'string'
            ? encodeURI(value)?.replace(/'/g, '`')
            : value
        }) ?? '',
    },
  })

  const enablePreOrder =
    isPreOrder && !!variants?.find((variant) => variant?.currentlyNotInStock)

  const outOfStockCTAText = getOutOfStockCTAText(hasStockAlert, isSpecialOrder)

  const animationVariants = {
    open: {
      opacity: 1,
      height: 'fit-content',
    },
    close: {
      opacity: 0,
      height: 0,
    },
  }

  return (
    <AnimatePresence>
      {displayQuickBuy && (
        <m.div
          key={`desktopButton-${shopifyId}`}
          initial={animationVariants.close}
          animate={animationVariants.open}
          exit={animationVariants.close}
          variants={animationVariants}
          transition={{ ease: 'easeInOut', duration: 0.2 }}
          className={cx(className, css.quickBuy, { isLoading })}
          onClick={(e) => {
            if (!enablePreOrder || isAvailableForSale) {
              e.preventDefault()
            }
          }}>
          {isLoading ? (
            <Loader />
          ) : (
            <ProductCardQuickBuyContent
              isAvailableForSale={isAvailableForSale}
              outOfStockCTAText={outOfStockCTAText}
              shouldEnablePreOrder={enablePreOrder}
              isUniqueVariant={isUniqueVariant}
              onAddToCart={onAddToCart}
              lang={lang}
              cartItems={cartItems}
              isPreOrder={isPreOrder}
              designer={designer}
              image={image}
              name={name}
              trackingData={trackingData}
              defaultVariant={defaultVariant}
              variantsAvailableToSell={variantsAvailableToSell}
              link={link}
            />
          )}
        </m.div>
      )}
      <div
        key={`mobile-${shopifyId}`}
        className={cx(css.mobileButton, {
          fullWidth: !isAvailableForSale || enablePreOrder,
        })}
        onClick={(e) => {
          e.preventDefault()
          if (!displayQuickBuy && !enablePreOrder && isAvailableForSale) {
            setDisplayQuickBuy(true)
          }
        }}>
        {isLoading ? (
          <Loader />
        ) : !isAvailableForSale ? (
          <OutOfStockButton
            className={cx(css.button, css.disabled)}
            link={link}
            outOfStockCTAText={outOfStockCTAText}
          />
        ) : enablePreOrder ? (
          <PreOrderButton
            className={cx(css.button, css.disabled)}
            link={link}
          />
        ) : (
          <Icon />
        )}
      </div>
    </AnimatePresence>
  )
}

function getOutOfStockCTAText(
  hasStockAlert: boolean,
  isSpecialOrder: boolean,
): string {
  if (hasStockAlert) {
    return CTA.QUICKBUY_STOCK_ALERT
  }

  if (isSpecialOrder) {
    return CTA.QUICKBUY_SPECIAL_ORDER
  }

  return CTA.OUT_OF_STOCK
}

export default function ProductCardQuickBuyWithErrorBoundary(
  props: ProductCardQuickBuyProps,
) {
  return (
    <ErrorBoundary>
      <ProductCardQuickBuy {...props} />
    </ErrorBoundary>
  )
}

import { CARD_IMAGE_LOADER } from '~/components/UI/ProductCard/types'

import { HERO_SLIDER_IMAGE_1, PORTRAIT_IMAGE } from '~/data/storyblok/images'

export const nElements = (n: number, element: any[]) =>
  Array.from(Array(n).keys()).map(() => element[0])

export const asRichText = ({ type = 'paragraph', attrs = {}, texts }) => ({
  type: 'doc',
  content: [
    {
      type,
      attrs,
      content: texts?.map((text) => ({
        text,
        type: 'text',
      })),
    },
  ],
})

export const asComplexRichText = ({ items = [] }) => {
  return {
    type: 'doc',
    content: items?.map((item) => ({
      type: item?.type,
      attrs: item?.attrs ?? {},
      content: item?.texts?.map((text) => ({
        text,
        type: 'text',
      })),
    })),
  }
}

export const LINK = { href: '/' }

export const INLINE_CTA_LINK = { href: '/', children: 'View all' }

export const SQUARED_CTA_LINK = { href: '/', children: 'All Products' }

export const PLACEHOLDER = 'Placeholder'

export const INLINE_CTA = 'Discover'

export const ROUNDED_CTA = 'Suscribe'

export const COLOR_HEXA = '#B1B08D'

export const PRICE = '14€'

export const DATE = '10/03/2021'

export const PRODUCT_VARIANT = '0.5 fl oz / 15mL'

export const STEP = asRichText({ type: 'paragraph', texts: ['STEP 1'] })

export const PIN = asRichText({ type: 'paragraph', texts: ['New'] })

export const DESIGNER_NAME_1 = 'Alexandra Jefford'
export const DESIGNER_NAME_2 = 'Sia Taylor'
export const DESIGNER_NAME_3 = 'Anette Ferdinanzes'

export const IMAGE_LABEL = 'Collection by Elhanatari'

export const HERO_CATEGORY_TITLE = 'Products'

export const HERO_CATEGORY_LINKS = [
  { href: '/', children: 'Fiançailles' },
  { href: '/', children: 'Alliances' },
  { href: '/', children: 'Diamant' },
  { href: '/', children: 'Pierres de couleur' },
  { href: '/', children: 'Perle' },
  { href: '/', children: 'Sans pierre' },
  { href: '/', children: 'Mixte' },
]

export const H1 = asRichText({
  type: 'heading',
  attrs: { level: 1 },
  texts: ['Say hello.'],
})

export const LONG_TITLE = asRichText({
  type: 'heading',
  attrs: { level: 1 },
  texts: [
    'Discover your personalized ritual for inner and outer beauty. Simply answer a few quick questions to get a tailored routine based on your skin type, lifestyle and goals',
  ],
})

export const H2 = asRichText({
  type: 'heading',
  attrs: { level: 2 },
  texts: ['New in'],
})

export const H3 = asRichText({
  type: 'heading',
  attrs: { level: 2 },
  texts: ['Say hello.'],
})

export const LABEL_PARAGRAPH = asRichText({
  type: 'paragraph',
  texts: ['Madrid, Spain'],
})

export const SUBTITLE_PARAGRAPH = asRichText({
  type: 'paragraph',
  texts: ['Short Information'],
})

export const QUOTE = asRichText({
  type: 'paragraph',
  texts: [
    '“Jewelry is my medium and to make it, by nature, is to be mindful of its techniques and the value of such fine materials. But this is secondary. The feeling I want to impart is one of radiance, in every sense: that of light, of emotion, of energy and, of course, that of beauty.”',
  ],
})

export const CITE = 'ANA KHOURI'

export const SMALL_PARAGRAPH = asRichText({
  type: 'paragraph',
  texts: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hac iaculis tortor, augue magna cras sit aliquam varius.',
  ],
})

export const SINGLE_PARAGRAPH = asRichText({
  type: 'paragraph',
  texts: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hac iaculis tortor, augue magna cras sit aliquam varius. Suspendisse pulvinar erat massa a purus nec. Nam arcu hac tortor nam est quam. Nisl egestas tristique nisi, faucibus vitae a. At neque, sit at accumsan, volutpat vel in. Turpis volutpat orci, quis id ultrices massa nunc. Condimentum dui fames amet sed dolor. At dui turpis scelerisque quis posuere. At et non tempor pretium. Blandit feugiat eu sit auctor consectetur elit elementum. Venenatis, netus urna bibendum egestas id aliquet odio.',
  ],
})

export const MULTIPLE_PARAGRAPH = asRichText({
  type: 'paragraph',
  texts: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hac iaculis tortor, augue magna cras sit aliquam varius. Suspendisse pulvinar erat massa a purus nec. Nam arcu hac tortor nam est quam. Nisl egestas tristique nisi, faucibus vitae a. At neque, sit at accumsan, volutpat vel in. Turpis volutpat orci, quis id ultrices massa nunc. Condimentum dui fames amet sed dolor. At dui turpis scelerisque quis posuere. At et non tempor pretium. Blandit feugiat eu sit auctor consectetur elit elementum. Venenatis, netus urna bibendum egestas id aliquet odio.',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hac iaculis tortor, augue magna cras sit aliquam varius. Suspendisse pulvinar erat massa a purus nec. Nam arcu hac tortor nam est quam. Nisl egestas tristique nisi, faucibus vitae a. At neque, sit at accumsan, volutpat vel in. Turpis volutpat orci, quis id ultrices massa nunc. Condimentum dui fames amet sed dolor. At dui turpis scelerisque quis posuere. At et non tempor pretium. Blandit feugiat eu sit auctor consectetur elit elementum. Venenatis, netus urna bibendum egestas id aliquet odio.',
  ],
})

export const PRIVACY_POLICY = asRichText({
  type: 'paragraph',
  texts: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hac iaculis tortor, augue magna cras sit aliquam varius. Suspendisse pulvinar erat massa a purus nec. Nam arcu hac tortor nam est quam. Nisl egestas tristique nisi, faucibus vitae a. At neque, sit at accumsan, volutpat vel in. Turpis volutpat orci, quis id ultrices massa nunc. Condimentum dui fames amet sed dolor. At dui turpis scelerisque quis posuere. At et non tempor pretium. Blandit feugiat eu sit auctor consectetur elit elementum. Venenatis, netus urna bibendum egestas id aliquet odio.',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hac iaculis tortor, augue magna cras sit aliquam varius. Suspendisse pulvinar erat massa a purus nec. Nam arcu hac tortor nam est quam. Nisl egestas tristique nisi, faucibus vitae a. At neque, sit at accumsan, volutpat vel in. Turpis volutpat orci, quis id ultrices massa nunc. Condimentum dui fames amet sed dolor. At dui turpis scelerisque quis posuere. At et non tempor pretium. Blandit feugiat eu sit auctor consectetur elit elementum. Venenatis, netus urna bibendum egestas id aliquet odio.',
  ],
})

export const ADDRESS = asComplexRichText({
  items: [
    {
      type: 'paragraph',
      texts: ['Boulevard des Filles du Calvaire'],
    },
    {
      type: 'paragraph',
      texts: ['75003 Paris'],
    },
    {
      type: 'paragraph',
      texts: ['+33 (0)1 40 24 27 17'],
    },
  ],
})

export const ACCOUNT = {
  registerTitle: asRichText({
    type: 'heading',
    attrs: { level: 2 },
    texts: ['Lorem ipsum'],
  }),
  registerLabel: 'I. Label',
  title: asRichText({
    type: 'heading',
    attrs: { level: 2 },
    texts: ['Lorem ipsum'],
  }),
}

export const PRODUCT = {
  name: 'Garnet Earrings',
  designer: 'Fernando Jorge',
  price: '$650',
  link: LINK,
  card_image: { loaderType: CARD_IMAGE_LOADER.STORYBLOK, data: PORTRAIT_IMAGE },
  image: { loaderType: CARD_IMAGE_LOADER.STORYBLOK, data: HERO_SLIDER_IMAGE_1 },
}

export const PRODUCT_TITLE = asRichText({
  type: 'paragraph',
  texts: ['Flicker garnet bracelet'],
})

export const DESIGNER_NAME_1_WYSIWYG = asRichText({
  type: 'paragraph',
  texts: ['Alexandra Jefford'],
})
export const DESIGNER_NAME_2_WYSIWYG = asRichText({
  type: 'paragraph',
  texts: ['Sia Taylor'],
})
export const DESIGNER_NAME_3_WYSIWYG = asRichText({
  type: 'paragraph',
  texts: ['Anette Ferdinanzes'],
})

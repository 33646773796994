import classnames from 'classnames/bind'
import React from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { SINGLE_ROUTES } from '~/lib/constants'

import CartItem from '~/components/UI/CartContent/CartItem'
import SquaredCta from '~/components/UI/SquaredCta'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useStyle } from '~/providers/StyleProvider'

import useGetCart from '~/hooks/cart/useGetCart'

import { CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type CartToastProps = {
  className?: string
  merchandiseId?: string
  attributeSelectedSize?: string
}

function CartToast({
  className,
  merchandiseId,
  attributeSelectedSize,
}: CartToastProps) {
  const t = useTranslate()
  const { cart } = useGetCart()
  const { global } = useGlobalData()
  const toastTitleStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })

  let addedProduct = null

  const addedProducts =
    cart?.products?.filter((item) => {
      return item?.merchandiseId === merchandiseId
    }) ?? null

  if (addedProducts && addedProducts?.length === 1) {
    addedProduct = addedProducts?.[0]
  } else {
    addedProduct =
      addedProducts?.filter((item) => {
        return (
          item?.merchandiseId === merchandiseId &&
          item?.selectedSize === attributeSelectedSize
        )
      })?.[0] ?? null
  }

  return (
    Boolean(addedProduct) && (
      <div className={cx(css.CartToast, className)}>
        {global?.cartPanel?.toastTitle && (
          <div className={css.toastTop}>
            <p className={cx(css.toastTitle, toastTitleStyle)}>
              {global?.cartPanel?.toastTitle}
            </p>
          </div>
        )}
        <div className={css.toastBody}>
          <CartItem isMini className={css.cartItem} {...addedProduct} />
        </div>
        <div className={css.toastBottom}>
          <SquaredCta
            className={css.cta}
            theme={GlobalThemeColors.Black}
            withBorder
            withBackground={false}
            href={SINGLE_ROUTES.cart.href}>
            {t(CTA.CART)} ({cart?.productsQuantity})
          </SquaredCta>
          <SquaredCta
            className={cx(css.cta)}
            href={cart?.checkoutUrl}
            theme={GlobalThemeColors.Black}
            withBackground
            withBorder={false}>
            {t(CTA.CONFIRM_CART)}
          </SquaredCta>
        </div>
      </div>
    )
  )
}

CartToast.defaultProps = {}

export default CartToast

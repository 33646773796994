export const SHOPIFY_PRODUCT_METAFIELDS = [
  {
    namespace: 'i18n',
    keys: ['title_en', 'details_en', 'details_fr', 'description_en'],
  },
]

export const SHOPIFY_VARIANT_METAFIELDS = [
  {
    namespace: 'variant',
    keys: ['hide'],
  },
]

export const SHOPIFY_PRODUCT_METAFIELDS_KEYS: string[] =
  SHOPIFY_PRODUCT_METAFIELDS.reduce((acc, metafield) => {
    const computedKeys = metafield.keys.map(
      (key) => `${metafield.namespace}.${key}`,
    )

    return [...acc, ...computedKeys]
  }, [])

export const SHOPIFY_VARIANT_METAFIELDS_KEYS: string[] =
  SHOPIFY_VARIANT_METAFIELDS.reduce((acc, metafield) => {
    const computedKeys = metafield.keys.map(
      (key) => `${metafield.namespace}.${key}`,
    )

    return [...acc, ...computedKeys]
  }, [])

import classnames from 'classnames/bind'
import React, { useCallback } from 'react'
import { TRACKING_EVENTS } from '~/lib/constants'

import { useTracker } from '~/providers/TrackerProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type SliderControlsProps = {
  className?: string
  buttonClassName?: string
  activeClassName?: string
  length?: number
  slideIndex?: number
  hasSmallerControls?: boolean
  setSlideIndex?: (index: number) => void
  withArrows?: boolean
  trackingData?: string
  trackingDataValue?: string
}

export default function SliderControls({
  className,
  buttonClassName,
  length,
  hasSmallerControls,
  setSlideIndex,
  slideIndex,
  trackingData,
  activeClassName,
  trackingDataValue,
}: SliderControlsProps) {
  //@ts-ignore
  const indexes = [...Array(length).keys()]
  const tracker = useTracker()

  const onClickIndex = useCallback(
    (index) => {
      setSlideIndex?.(index)
    },
    [setSlideIndex, tracker, trackingData, trackingDataValue],
  )

  return length > 1 ? (
    <div className={cx(css.SliderControls, className, { hasSmallerControls })}>
      <div className={css.buttonsWrapper}>
        {indexes?.map((_, index) => (
          <span
            key={`sliderControl${index}`}
            onClick={() => onClickIndex(index)}
            className={cx(
              css.button,
              buttonClassName,
              slideIndex === index && activeClassName,
            )}
          />
        ))}
      </div>
    </div>
  ) : null
}

SliderControls.defaultProps = {
  hasSmallerControls: false,
  trackingData: false,
  trackingDataValue: false,
}

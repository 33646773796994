import { SHOPIFY_VARIANT_SIZE_DEFAULT_KEYS } from '~/lib/constants'

/**
 * It checks if a string is a size or default variant name
 * @param {string} text - The text to check if it's a size or default variant name.
 * @returns A boolean value.
 */
export function isSizeOrDefaultVariantName(text: string) {
  return SHOPIFY_VARIANT_SIZE_DEFAULT_KEYS.includes(text.toLowerCase())
}

import { ISbStoriesParams } from 'storyblok-js-client'
import { PRODUCT_CARDS, PRODUCT_PAGE } from '~/lib/fetch-links'
import Storyblok, { getStoryblokVersion, pageSbParams } from '~/lib/storyblok'
import { STORYBLOK_TYPES } from '~/lib/storyblok-types'

import { ProductCardProps } from '~/components/UI/ProductCard'

import serializeProductCard from '~/data/serialize-product-card'

const WANTED_RECOMMENDATIONS = 3

type StoryRecommandationData = {
  uuid: string
  id: string
}
export type RecommendationData = {
  currentStory: StoryRecommandationData
  categoryUUID?: string
  designerUUID?: string
  siblingsStories?: StoryRecommandationData[]
}

export function serializeRecommendationData(
  data,
  siblingsData,
): RecommendationData {
  return {
    categoryUUID: data?.content?.categories?.[0]?.uuid ?? null,
    designerUUID: data?.content?.designer?.uuid ?? null,
    currentStory: { uuid: data?.uuid, id: data?.id },
    siblingsStories:
      siblingsData?.items
        ?.filter((item) => {
          return item?.uuid !== siblingsData?.active?.uuid
        })
        ?.map((item) => {
          return { uuid: item?.uuid, id: item?.storyID }
        }) ?? [],
  }
}

/**
 * It takes a product page's data, and returns a list of recommended products
 * @param  - `rawData` - the raw data from Storyblok
 * @returns An object with an array of items.
 */
export default async function serializeRecommendedProducts({
  rawData,
  productVariationsData,
  locale,
}): Promise<ProductCardProps[]> {
  const { categoryUUID, designerUUID, currentStory, siblingsStories } =
    serializeRecommendationData(rawData, productVariationsData)

  const excludedIds = [
    currentStory?.id,
    ...(siblingsStories?.length > 0
      ? siblingsStories.map((sibling) => sibling?.id)
      : []),
  ]?.join(',')

  const baseSBParams: ISbStoriesParams = {
    resolve_links: 'url',
    starts_with: `${STORYBLOK_TYPES.PRODUCT}/`,
    per_page: WANTED_RECOMMENDATIONS,
    resolve_relations: PRODUCT_CARDS,
    cv: pageSbParams.cv,
    ...(pageSbParams?.instance ?? {}),
  }

  const filters = {
    categories: {
      ...(categoryUUID
        ? {
            categories: {
              in: designerUUID,
            },
          }
        : {}),
    },
    designers: {
      ...(designerUUID
        ? {
            designer: {
              in: designerUUID,
            },
          }
        : {}),
    },
  }

  const siblingsQueryParams: ISbStoriesParams = {
    ...baseSBParams,
    by_uuids: siblingsStories?.map((sibling) => sibling?.uuid)?.join(','),
    excluding_ids: currentStory.id,
  }

  const mainQueryParams: ISbStoriesParams = {
    ...baseSBParams,
    excluding_ids: excludedIds,
  }

  try {
    const responseItems = []

    /* It's getting the siblings stories and adding them to the responseItems array. */
    if (siblingsStories?.length > 0) {
      const siblingsResponse =
        (
          await Promise.all([Storyblok.get(`cdn/stories`, siblingsQueryParams)])
        )?.[0]?.data?.stories ?? []

      if (siblingsResponse) {
        responseItems.push(siblingsResponse)

        mainQueryParams[`per_page`] =
          mainQueryParams[`per_page`] - siblingsResponse?.length
      }
    }

    if (
      (categoryUUID || designerUUID) &&
      responseItems?.length < WANTED_RECOMMENDATIONS
    ) {
      const categoryAndDesignerResponse =
        (
          await Promise.all([
            Storyblok.get(`cdn/stories`, {
              ...mainQueryParams,
              filter_query: { ...filters.categories, ...filters.designers },
            }),
          ])
        )?.[0]?.data?.stories ?? []

      if (categoryAndDesignerResponse?.length > 0) {
        responseItems.push(categoryAndDesignerResponse)

        mainQueryParams[`per_page`] =
          mainQueryParams[`per_page`] - categoryAndDesignerResponse?.length
      }

      if (mainQueryParams[`per_page`] !== 0) {
        const designerResponse =
          (
            await Promise.all([
              Storyblok.get(`cdn/stories`, {
                ...mainQueryParams,
                filter_query: { ...filters.designers },
              }),
            ])
          )?.[0]?.data?.stories ?? []

        if (designerResponse?.length > 0) {
          responseItems.push(designerResponse)
        }
      }
    }

    return (
      Promise.all(
        responseItems?.flat()?.map(async (responseItem) => {
          return (await serializeProductCard(responseItem, locale)) ?? null
        }),
      ) ?? []
    )
  } catch (e) {
    console.log(e)
    return []
  }
}

import { ProductDataProps } from '@unlikelystudio/react-abstract-components'

import { ProductHeaderProps } from '~/components/UI/ProductHeader'

import getAlternateUrl from '~/utils/alternate-url'

import { StoryblokShopifyData } from '~/data/serialize-storyblok-shopify-data'

export default function serializeJSONLD(
  content: ProductHeaderProps,
  shopify: StoryblokShopifyData,
  url: string,
  name: string,
  description: string,
): ProductDataProps {
  return {
    name: name ?? null,
    brand: {
      name: 'WHITEbIRD',
    },
    description: description ?? null,
    sku: shopify?.shopifyId ?? null,
    mpn: null,
    image:
      shopify?.items?.map((item) => item.image.src)?.filter((item) => item) ??
      null,
    offers: {
      price: `${shopify?.defaultVariant?.unformattedPrice?.amount ?? '0'}`,
      priceCurrency:
        shopify?.defaultVariant?.unformattedPrice?.currencyCode ?? null,
      url: getAlternateUrl(url),
      availability: shopify?.isAvailable ? 'InStock' : 'OutOfStock',
    },
  }
}

import { STORYBLOK_SUBTYPES, STORYBLOK_TYPES } from './storyblok-types'

const DELIMITER = ','

const getResolveRelations = <T extends any[]>(array: T) =>
  array?.flat()?.join(DELIMITER) ?? ''

export const PRODUCT_CARD = [
  `${STORYBLOK_TYPES.PRODUCT}.designer`,
  `${STORYBLOK_TYPES.PRODUCT}.shopify_product`,
  `${STORYBLOK_TYPES.PRODUCT}.product_sustainability_tags`,
  `${STORYBLOK_TYPES.PRODUCT}.categories`,
]

export const PRODUCT = [
  `${STORYBLOK_TYPES.PRODUCT}.categories`,
  `${STORYBLOK_TYPES.PRODUCT}.product_metal`,
  `${STORYBLOK_TYPES.PRODUCT}.product_stone`,
  `${STORYBLOK_TYPES.PRODUCT}.product_selections`,
  `${STORYBLOK_TYPES.PRODUCT}.product_hidden_selections`,
  `${STORYBLOK_TYPES.PRODUCT}.size_guide`,
]

const PRODUCT_CATEGORY = [
  `${STORYBLOK_SUBTYPES.PRODUCT_CATEGORY}.category_page_relation`,
]

const DESIGNER = []

const FILTERS = [
  `${STORYBLOK_TYPES.DESIGNER}.relation`,
  `${STORYBLOK_TYPES.METAL_STONE_PAGE}.relation`,
  `${STORYBLOK_TYPES.CATEGORY_PAGE}.sub_navigation`,
  'filters.designers',
  'filters.stone',
  'filters.metal',
  'filters.categories',
  'filters.selections',
  'filters.hidden_selections',
]

const BLOG = []

const PRESS = []

const GLOBALS = []

const CART = []

const AUTHENTICATION = []

export const PRODUCT_CARDS = getResolveRelations([PRODUCT_CARD])
export const GLOBAL = getResolveRelations([GLOBALS])
export const ALGOLIA_FETCH_LINKS = getResolveRelations([
  PRODUCT_CARD,
  PRODUCT_CATEGORY,
  PRODUCT,
])

export const PRODUCT_CATEGORIES = getResolveRelations([PRODUCT_CATEGORY])
export const ARCHIVE_PAGE = getResolveRelations([FILTERS])
export const PRODUCT_PAGE = getResolveRelations([
  PRODUCT_CATEGORY,
  PRODUCT_CARD,
  PRODUCT,
])
export const BLOG_ARTICLE_PAGE = getResolveRelations([BLOG])
export const PRESS_ARTICLE_PAGE = getResolveRelations([PRESS])
export const CART_PAGE = getResolveRelations([CART])
export const CATEGORY_PAGE = getResolveRelations([FILTERS, PRODUCT_CATEGORY])
export const METAL_STONE_PAGE = getResolveRelations([FILTERS])
export const DESIGNER_PAGE = getResolveRelations([FILTERS])

export const PAGE = getResolveRelations([])

export const AUTHENTICATION_PAGE = getResolveRelations([AUTHENTICATION])

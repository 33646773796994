import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { OriginType, TRACKING_EVENTS } from '~/lib/constants'
import getSingleRouteHref from '~/lib/get-single-route-href'
import { STORYBLOK_TYPES } from '~/lib/storyblok-types'

import { useCustomerContext } from '@unlikelystudio/react-ecommerce-hooks'

import IconCta, { Icons } from '~/components/UI/IconCta'

import { useTracker } from '~/providers/TrackerProvider'

import useAddToWishlist from '~/hooks/account/wishlist/useAddToWishlist'
import useGetWishlist from '~/hooks/account/wishlist/useGetWishlist'
import useRemoveFromWishlist from '~/hooks/account/wishlist/useRemoveFromWishlist'
import { ProductTrackingData } from '~/hooks/tracking/useTrackProductCardsList'
import { useINPTrick } from '~/hooks/useINPTrick'
import useLocale from '~/hooks/useLocale'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type WishlistIconProps = {
  className?: string
  uuid: string
  variantId: string
  trackingData?: ProductTrackingData
}

function WishlistIcon({
  className,
  uuid,
  variantId,
  trackingData,
}: WishlistIconProps) {
  const locale = useLocale()
  const { customerAccessToken } = useCustomerContext()
  const { data: wishlist } = useGetWishlist()
  const tracker = useTracker()
  const router = useRouter()
  const { hasInteracted, onInteract } = useINPTrick()
  const { mutate: addToWishlist, isLoading: isAdding } = useAddToWishlist({
    onSuccess: () => triggerEvent(TRACKING_EVENTS.ADD_TO_WISHLIST),
  })
  const { mutate: removeFromWishlist, isLoading: isRemoving } =
    useRemoveFromWishlist()

  const triggerEvent = (event: string) => {
    tracker.emit<typeof event>(event, {
      origin: OriginType.DETAIL_VIEW,
      item: trackingData,
    })
  }

  const product = wishlist?.products?.find(
    (product) =>
      product?.uuid === uuid && product?.shopify_admin_id === variantId,
  )
  return (
    <IconCta
      className={cx(
        className,
        css.WishlistIcon,
        hasInteracted && css.hasInteracted,
      )}
      icon={Icons.Heart}
      iconClassName={cx(css.icon, { isActive: Boolean(product) })}
      loading={isAdding || isRemoving}
      ariaLabel="wish-list-icon"
      onClick={(e) => {
        e.preventDefault()
        if (!customerAccessToken) {
          return router.push(
            `${getSingleRouteHref(STORYBLOK_TYPES.LOGIN, locale)}?redirectUrl=${
              router.asPath
            }`,
          )
        }

        if (!product) {
          onInteract(() => addToWishlist({ id: uuid, shopifyId: variantId }))
        } else onInteract(() => removeFromWishlist({ id: product?.id }))
      }}
    />
  )
}

WishlistIcon.defaultProps = {}

export default WishlistIcon

import linkResolver from '~/lib/link-resolver'
import { STORYBLOK_SUBTYPES, STORYBLOK_TYPES } from '~/lib/storyblok-types'

import { LinkProps } from '@unlikelystudio/react-abstract-components'

import { capitalize } from '~/utils/capitalize'
import i18n from '~/utils/i18n'
import { replaceStringFromArray } from '~/utils/replace-string-from-array'

import { GENERAL } from './dictionary'

export type UidsProps = {
  uid: string
  type?: string
  name?: string
  fullSlug?: string
}
export type SerializedBreadcrumbProps = {
  rootLink?: LinkProps | LinkProps[]
  items?: UidsProps[]
  locale?: string
}

type ItemFromUid = {
  uid?: string
  name?: string
  type?: string
  locale?: string
  fullSlug?: string
}

export function formatFromUid({
  uid,
  name,
  type,
  fullSlug,
  locale,
}: ItemFromUid) {
  // Trapping the homepage to avoid displaying it twice
  if (type === STORYBLOK_TYPES.HOMEPAGE) {
    return { children: null, href: null }
  }

  return {
    children: name
      ? capitalize(name)
      : uid
      ? // Replace - by space
        capitalize(
          replaceStringFromArray(
            uid?.split('-')?.join(' ')?.toLowerCase(),
            Object.values({ ...STORYBLOK_TYPES, ...STORYBLOK_SUBTYPES }),
            '',
            '/',
          )
            ?.split('_')
            .join(' '),
        )
      : null,
    href:
      linkResolver(
        {
          content: {
            component: type,
          },
          slug: uid,
          default_full_slug: fullSlug,
        },
        locale,
      ) ?? null,
  }
}

export default function serializeBreadcrumb({
  rootLink = null,
  items = [],
  locale,
}: SerializedBreadcrumbProps): LinkProps[] {
  return [
    { children: i18n.t(GENERAL.HOME_PAGE), href: `/${locale}` },
    ...(rootLink ? (!Array.isArray(rootLink) ? [rootLink] : rootLink) : []),
    ...(items
      ? items.map((item, i) => {
          return {
            ...formatFromUid({
              ...item,
              locale,
            }),
            ...(i === items?.length - 1 && {
              href: null,
            }),
          }
        })
      : []),
  ].filter((link) => link.children)
}

import { useRouter } from 'next/router'
import { useTranslate } from 'react-polyglot'

import { DisabledButtonProps } from '~/components/UI/ProductCard/ProductCardQuickBuy'
import SquaredCta from '~/components/UI/SquaredCta'

type OutOfStockButtonProps = DisabledButtonProps & {
  outOfStockCTAText: string
}

export function OutOfStockButton({
  className,
  outOfStockCTAText,
  link,
}: OutOfStockButtonProps) {
  const t = useTranslate()
  const router = useRouter()

  return (
    <SquaredCta
      className={className}
      withBackground
      withHover={false}
      onClick={() => link?.href && router.push(link?.href)}>
      {t(outOfStockCTAText)}
    </SquaredCta>
  )
}

import { UnlikelyImage } from '@unlikelystudio/commerce-connector'

import { Image } from '~/data/serialize-image'

/**
 * Image serializer
 * @param image
 * @returns Serialized image
 */
export default function serializeShopifyImage(image: UnlikelyImage): Image {
  const src = image?.url ?? null
  const alt = image?.altText ?? null
  const width = image?.width
  const height = image?.height

  const dimensions = {
    width: width ? width : null,
    height: height ? height : null,
  }

  return src
    ? {
        src: src ?? null,
        width: dimensions?.width,
        height: dimensions?.height,
        alt,
      }
    : null
}

import { LinkProps } from '@unlikelystudio/react-abstract-components'

import serializeLink from '~/data/serialize-link'

export type ProductAttribute = {
  uuid?: string
  storyID?: string
  label?: string
  slug?: string
  link?: LinkProps
}

export function serializeProductAttribute(data, locale): ProductAttribute {
  const link = serializeLink(data, locale) ?? null

  return {
    uuid: data?.uuid ?? null,
    slug: data?.slug ?? null,
    label: data?.content?.name ?? data?.name ?? null,
    storyID: data?.id ?? null,
    link,
  }
}

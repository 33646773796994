import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import React, { useState } from 'react'

import Image, { ImageProps } from '~/components/UI/Image'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ImageHoverProps = ImageProps & {
  className?: string
  isHovering: boolean
}

function ImageHover({ className, isHovering, ...rest }: ImageHoverProps) {
  const [isImageHoverLoaded, setIsImageHoverLoaded] = useState(false)

  return (
    <AnimatePresence>
      {isHovering && (
        <m.div
          className={cx(className)}
          initial={{ opacity: 0 }}
          animate={
            isImageHoverLoaded
              ? {
                  opacity: 1,
                  transition: { duration: 0.25 },
                }
              : {}
          }
          exit={{
            opacity: 0,
            transition: { duration: 0.25 },
          }}>
          <Image
            onLoadingComplete={() => {
              setIsImageHoverLoaded(true)
            }}
            {...rest}
          />
        </m.div>
      )}
    </AnimatePresence>
  )
}

ImageHover.defaultProps = {}

export default ImageHover

import { WbProductVariant } from '~/@types/product'
import { isSizeOrDefaultVariantName } from '~/utils/is-size-or-default-variant-name'
import lookupAdjustableVariants from '~/utils/check-adjustable-variants-lookup'

/**
 * It takes a list of variants, and returns a list of variants with an additional property called
 * isVariantSellable
 * @param variants - the array of variants
 * @param offset - The number of variants to skip before the first sellable variant.
 * @returns An array of variants with the adjustments properties set
 */
export function serializeAdjustableVariants(
  variants: WbProductVariant[],
  offset: number,
) {
  const compiledVariants = variants?.map((variant) => {
    const variantOptions = variant?.selectedOptions
    const hasSizeOption =
      variantOptions?.filter((option) => {
        const optionName = option?.name
        return isSizeOrDefaultVariantName(optionName)
      })?.length > 0 ?? false

    if (hasSizeOption) {
      let siblings = []
      let isVariantSellable = true
      let adjustableVariantToSell = variant
      const isVariantAdjusting = variant?.availableForSale === false

      if (isVariantAdjusting) {
        if (variantOptions?.length > 1) {
          // Group siblings by the second option
          const secondOption = variantOptions?.[1]
          siblings = variants?.filter((sibling) => {
            return (
              sibling?.selectedOptions?.[1]?.name === secondOption?.name &&
              sibling?.selectedOptions?.[1]?.value === secondOption?.value
            )
          })
        } else {
          siblings = variants
        }

        const currentVariantIndexAmongSiblings = siblings?.findIndex(
          (currentSibling, index) => {
            return currentSibling.id === variant.id
          },
        )

        const lookupCheck = lookupAdjustableVariants(
          variant,
          siblings,
          currentVariantIndexAmongSiblings,
          offset,
        )

        isVariantSellable = lookupCheck?.isVariantSellable

        if (isVariantSellable && lookupCheck.variantToSell) {
          adjustableVariantToSell = lookupCheck.variantToSell
        }
      }

      let variantToPush = variant

      if (isVariantSellable && adjustableVariantToSell) {
        variantToPush = {
          ...adjustableVariantToSell,
          title: variant.title,
          selectedOptions: variant.selectedOptions,
        }
      }

      return {
        ...variantToPush,
        isVariantAdjusting,
        isVariantSellable,
        availableForSale: isVariantSellable !== false,
      }
    } else {
      return { ...variant, isVariantAdjusting: false, isVariantSellable: true }
    }
  })

  return compiledVariants
}

import { STORYBLOK_TYPES } from '~/lib/storyblok-types'

import { LinkProps } from '@unlikelystudio/react-abstract-components'

import i18n from '~/utils/i18n'

import { GENERAL } from '~/data/dictionary'
import {
  formatFromUid,
  SerializedBreadcrumbProps,
  UidsProps,
} from '~/data/serialize-breadcrumb'

export type SerializedProductBreadcrumbProps = SerializedBreadcrumbProps & {
  pageData?: any
  categories?: any[]
}

function generateCategoryEntriesFromPageData(categories: any): UidsProps[] {
  return [
    {
      uid: null,
      name: i18n.t(GENERAL.CATEGORIES),
      type: STORYBLOK_TYPES.CATEGORY_PAGE,
    },
    ...(categories?.map((cat) => {
      return {
        uid: null,
        name: cat?.content?.title ?? null,
        ...(cat?.content?.category_page_relation
          ? { type: cat?.content?.category_page_relation?.content?.component }
          : {}),
        ...(cat?.content?.category_page_relation
          ? {
              fullSlug: cat?.content?.category_page_relation?.default_full_slug,
            }
          : {}),
      }
    }) ?? []),
  ]
}

export default function serializeProductBreadcrumb({
  pageData = null,
  categories,
  locale,
}: SerializedProductBreadcrumbProps): LinkProps[] {
  const categoryData = pageData?.content?.categories?.[0] ?? null

  const itemsToFormat = []

  if (categoryData) {
    itemsToFormat.push(...generateCategoryEntriesFromPageData(categories))
  }

  itemsToFormat.push({
    uid: pageData?.slug,
    type: pageData?.content?.component,
    fullSlug: pageData?.default_full_slug ?? null,
    name: null,
  })
  return [
    { children: i18n.t(GENERAL.HOME_PAGE), href: `/${locale}` },
    ...(itemsToFormat
      ? itemsToFormat.map((item) =>
          formatFromUid({
            ...item,
            locale,
          }),
        )
      : []),
  ].filter((link) => link.children)
}

import { Spinner } from '@unlikelystudio/react-abstract-components'

import css from '~/components/UI/ProductCard/ProductCardQuickBuy/styles.module.scss'

export function Loader() {
  return (
    <div className={css.loaderWrapper}>
      <Spinner className={css.spinner} color="#2c2c2c" />
    </div>
  )
}

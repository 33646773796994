import classnames from 'classnames/bind'
import { ReactNode, useEffect } from 'react'
import { useTranslate } from 'react-polyglot'
import useGetCart from 'src/hooks/cart/useGetCart'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { SHOPIFY_ATTRIBUTES, SHOPIFY_CART_ATTRIBUTES } from '~/lib/constants'

import {
  UnlikelyCartLineInput,
  UnlikelyCustomAttributes,
} from '@unlikelystudio/commerce-connector'
import {
  useAddCartLines,
  useUpdateCartAttributes,
} from '@unlikelystudio/react-ecommerce-hooks'

import { CtaProps } from '~/components/Abstracts/Cta'
import CartToast from '~/components/Toasts/CartToast'
import InlineCta from '~/components/UI/InlineCta'
import SquaredCta, { SquaredCtaProps } from '~/components/UI/SquaredCta'

import { useToastProvider } from '~/providers/ToastCenterProvider'

import useAlerts from '~/hooks/useAlerts'
import { useINPTrick } from '~/hooks/useINPTrick'
import useLang from '~/hooks/useLang'
import useTextColorFromTheme from '~/hooks/useTextColorFromTheme'

import { objectAsAttributes } from '~/utils/attributes'

import { CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type OnAddToCartButtonCallback = {
  isStockAlert: boolean
}

export type AddToCartButtonProps = Pick<SquaredCtaProps, 'spinnerClassName'> & {
  className?: string
  disabled?: boolean
  inline?: boolean
  cartItems?: UnlikelyCartLineInput[]
  triggerAddToCart?: boolean
  theme?:
    | typeof GlobalThemeColors.Black
    | typeof GlobalThemeColors.White
    | typeof GlobalThemeColors.Transparent
  CtaProps?: CtaProps
  attributes?: UnlikelyCustomAttributes
  onClick?(): void
  onAdded?(): void
  isOutOfStock?: boolean
  isPreOrder?: boolean
  children?: ReactNode
}

function AddToCartButton({
  className,
  cartItems,
  CtaProps,
  disabled,
  isOutOfStock,
  isPreOrder,
  onClick,
  onAdded,
  theme,
  inline,
  triggerAddToCart,
  children,
  spinnerClassName,
}: AddToCartButtonProps) {
  const { textColor: color } = useTextColorFromTheme()
  const { addAlert } = useToastProvider()
  const componentColor = theme ?? color
  const { hasInteracted, onInteract } = useINPTrick()
  const triggerAlert = useAlerts()
  const t = useTranslate()
  const lang = useLang()

  const { mutate: addItems, isLoading } = useAddCartLines({
    onSuccess: (_, variables) => {
      const attributeSelectedSize =
        variables?.[0]?.attributes?.find((item) => {
          return item?.key === SHOPIFY_ATTRIBUTES.selectedSize
        })?.value ?? null

      // Selected size
      onAdded?.()
      addAlert({
        uid: `add-to-cart-${variables?.[0]?.merchandiseId}`,
        children: (
          <CartToast
            merchandiseId={variables?.[0]?.merchandiseId}
            attributeSelectedSize={attributeSelectedSize}
          />
        ),
      })
    },
    onError: () => triggerAlert(),
  })

  const { cart } = useGetCart()

  const { mutate: updateCartCustomAttribute } = useUpdateCartAttributes({
    onSuccess: () => addToCart(),
    onError: () => triggerAlert(),
  })

  const onAddToCartClick = () => {
    if (cartItems.length === 0) return
    const { attributes } = cart ?? {}
    const processedAttributes = objectAsAttributes(attributes)

    return updateCartCustomAttribute({
      cartId: cart?.cartId,
      attributes: [
        ...processedAttributes,
        {
          key: SHOPIFY_CART_ATTRIBUTES.cartLang,
          value: lang,
        },
      ],
    })
  }

  const addToCart = () => {
    if (!isLoading) {
      if (onClick) onClick()
      addItems(cartItems)
    }
  }

  useEffect(() => {
    if (triggerAddToCart) onAddToCartClick()
  }, [triggerAddToCart])

  return inline ? (
    <InlineCta
      {...CtaProps}
      className={cx(
        className,
        css.addToCart,
        hasInteracted && css.hasInteracted,
      )}
      theme={componentColor}
      textPreset={GlobalTextPreset.Cta12Grotesk}
      onClick={() => onInteract(onAddToCartClick)}
      isLoading={isLoading}
      disabled={isOutOfStock || disabled}
      withBackground>
      {isOutOfStock
        ? t(CTA.OUT_OF_STOCK)
        : isPreOrder
        ? t(CTA.PRE_ORDER)
        : t(CTA.ADD_TO_CART)}
    </InlineCta>
  ) : (
    <SquaredCta
      {...CtaProps}
      className={cx(
        className,
        css.addToCart,
        hasInteracted && css.hasInteracted,
      )}
      theme={componentColor}
      textPreset={GlobalTextPreset.Cta12Grotesk}
      onClick={() => {
        onInteract(onAddToCartClick)
      }}
      isLoading={isLoading}
      disabled={isOutOfStock || disabled}
      spinnerClassName={spinnerClassName}>
      {children
        ? children
        : isOutOfStock
        ? t(CTA.OUT_OF_STOCK)
        : isPreOrder
        ? t(CTA.PRE_ORDER)
        : t(CTA.ADD_TO_CART)}
    </SquaredCta>
  )
}

AddToCartButton.defaultProps = {
  isOutOfStock: false,
}

export default AddToCartButton

import { PROJECT_NAME } from '~/lib/constants'

import { ProductTrackingObject } from '~/hooks/tracking'

import numberAsBoolean from '~/utils/number-as-boolean'
import { getReferenceIdFromAdminId } from '~/utils/shopify-ids'

type ListProps = {
  listName?: string
  listPosition?: number
}

export type ProductTrackingData = {
  item_name?: string
  item_id?: string
  item_brand?: string
  productDesigner?: string | string[]
  item_variant?: string
  item_sku?: string
  item_category?: string
  item_subcategory?: string
  index?: number
  priceAmount?: number
  quantity?: number
}

export function fromProductCardPropsToProductTrackingData(
  product: ProductTrackingObject,
  { listName, listPosition }: ListProps,
): ProductTrackingData {
  const id =
    typeof product?.shopifyId === 'string'
      ? getReferenceIdFromAdminId(product?.shopifyId)
      : product?.shopifyId

  const variantId =
    typeof product?.variantId === 'string'
      ? getReferenceIdFromAdminId(product?.variantId)
      : product?.variantId
  const formattedId = `${id ? id : ''}${variantId ? variantId : ''}`

  return {
    item_brand: PROJECT_NAME,
    item_sku: product?.sku ?? '',
    quantity: product?.quantity ?? 1,
    ...(product?.name && {
      item_name: product?.name,
    }),
    ...(formattedId?.length > 0 && { item_id: formattedId }),
    ...(product?.designer && { productDesigner: product?.designer }),
    ...(product?.size && { item_variant: product.size }),
    ...(product?.categories?.[0]
      ? { item_category: product?.categories?.[0] }
      : {}),
    ...(product?.categories?.length > 1 && {
      item_subcategory: product?.categories?.join('-'),
    }),
    ...(numberAsBoolean(listPosition) && {
      index: listPosition,
    }),
    ...(product?.priceAmount ? { price: product?.priceAmount } : {}),
  }
}

import { UnlikelyCartLineInput } from '@unlikelystudio/commerce-connector'

import { SHOPIFY_ATTRIBUTES, SHOPIFY_LOCALIZED_ATTRIBUTES } from './constants'

type CustomAttributesProps = {
  slug?: string
  name?: string
  lang?: string
  designer?: string
  reference?: string
  image?: string
  category?: string
  shopifyId?: string
  trackingData?: string
  sample?: string
  gift?: string
  giftPrice?: string
  isPreOrder?: string | boolean
  isAdjusting?: string | boolean
  isUniqueSize?: string | boolean
  selectedSize?: string
}

type ProcessConfiguratorCartItemsProps = {
  merchandiseId: UnlikelyCartLineInput['merchandiseId']
  quantity: UnlikelyCartLineInput['quantity']
  attributes?: CustomAttributesProps
}

export function addCustomObject(key, value) {
  return value
    ? [
        ...(Array.isArray(key)
          ? key?.map((subkey) => ({ key: subkey, value }))
          : [{ key, value }]),
      ]
    : []
}

export function processCartItems({
  merchandiseId,
  quantity,
  attributes,
}: ProcessConfiguratorCartItemsProps) {
  const isAdjusted = attributes?.isAdjusting?.toString() ?? 'false'

  return merchandiseId
    ? [
        {
          merchandiseId,
          quantity,
          attributes: [
            ...(attributes
              ? [
                  ...addCustomObject(SHOPIFY_ATTRIBUTES.name, attributes?.name),

                  ...addCustomObject(
                    SHOPIFY_ATTRIBUTES.designer,
                    attributes?.designer,
                  ),

                  ...addCustomObject(
                    SHOPIFY_ATTRIBUTES.reference,
                    attributes?.reference,
                  ),

                  ...addCustomObject(SHOPIFY_ATTRIBUTES.slug, attributes?.slug),

                  ...addCustomObject(
                    SHOPIFY_ATTRIBUTES.image,
                    attributes?.image,
                  ),

                  ...addCustomObject(
                    SHOPIFY_ATTRIBUTES.shopifyId,
                    attributes?.shopifyId,
                  ),

                  ...addCustomObject(
                    SHOPIFY_ATTRIBUTES.trackingData,
                    attributes?.trackingData ?? null,
                  ),

                  ...addCustomObject(
                    SHOPIFY_ATTRIBUTES.isPreOrder,
                    attributes?.isPreOrder?.toString() ?? 'false',
                  ),

                  ...addCustomObject(
                    SHOPIFY_ATTRIBUTES.isAdjusting,
                    attributes?.isAdjusting?.toString() ?? 'false',
                  ),

                  ...addCustomObject(
                    SHOPIFY_ATTRIBUTES.isUniqueSize,
                    attributes?.isUniqueSize?.toString() ?? 'false',
                  ),

                  ...addCustomObject(
                    SHOPIFY_ATTRIBUTES.selectedSize,
                    attributes?.selectedSize ?? null,
                  ),

                  ...(attributes?.lang && isAdjusted === 'true'
                    ? addCustomObject(
                        SHOPIFY_LOCALIZED_ATTRIBUTES?.[
                          `adjustedSize_${attributes?.lang}`
                        ],
                        attributes?.selectedSize ?? null,
                      )
                    : []),
                ]
              : []),
          ],
        },
      ]
    : []
}

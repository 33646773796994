import { WbProductVariant } from '~/@types/product'
import { getMetafieldValue } from '~/lib/shopify/metafields/helpers'

import {
  UnlikelyVariant,
  UnlikelyVariantStoreAvailability,
} from '@unlikelystudio/commerce-connector'

import { ProductDetailsAccordionItemsProps } from '~/components/UI/ProductHeader/ProductDetails'

import { PRODUCT } from '~/data/dictionary'
import serializePrice from '~/data/serialize-price'
import { asComplexRichText } from '~/data/storyblok/texts'

export function serializeUnlikelyVariantToWbVariant(
  variant: UnlikelyVariant,
  locale: string,
): WbProductVariant {
  const isDefinitelyHidden =
    getMetafieldValue('variant.hide', variant?.metafields) === 'true'

  if (isDefinitelyHidden) return null

  const price =
    serializePrice(
      locale,
      variant?.price?.currencyCode,
      variant?.price?.amount,
    ) ?? null

  return {
    id: variant?.id ?? null,
    title: variant?.title ?? null,
    price,
    unformattedPrice:
      {
        formattedPrice: price ?? null,
        amount: variant?.price?.amount ?? null,
        currencyCode: variant?.price?.currencyCode ?? null,
      } ?? null,
    sku: variant?.sku ?? null,
    selectedOptions: variant?.selectedOptions ?? null,
    availableForSale:
      (variant?.availableForSale && variant?.quantityAvailable > 0) ?? false,
    quantityAvailable: variant?.quantityAvailable ?? 0,
    // NOTE : This could break in some of the PDP cases
    // Like the alert or others
    currentlyNotInStock:
      (variant?.availableForSale && variant?.quantityAvailable <= 0) ?? false,
    compareAtPrice: variant?.compareAtPrice
      ? serializePrice(
          locale,
          variant?.compareAtPrice?.currencyCode,
          variant?.compareAtPrice?.amount,
        )
      : null,
    shops: serializeVariantShops(variant?.storeAvailability) ?? [],
    image: variant?.image ?? null,
  }
}

function serializeVariantShops(
  shops: UnlikelyVariantStoreAvailability[],
): ProductDetailsAccordionItemsProps[] {
  const availableShops =
    shops
      ?.map((shop) => {
        if (
          shop?.available &&
          shop?.quantityAvailable > 0 &&
          shop?.location?.name
        ) {
          return {
            name: shop?.location?.name,
          }
        } else {
          return null
        }
      })
      ?.filter((item) => item) ?? []

  const accordionItem =
    availableShops?.length > 0
      ? [
          {
            headline: PRODUCT.EXPAND_PRODUCT_SHOPS,
            text: asComplexRichText({
              items:
                availableShops?.map((shop) => {
                  return {
                    type: 'paragraph',
                    texts: [shop?.name],
                  }
                }) ?? [],
            }),
          },
        ]
      : []

  return accordionItem
}

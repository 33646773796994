import classnames from 'classnames/bind'
import React, { forwardRef } from 'react'

import Slider, {
  SliderProps,
  useSliderState,
} from '@unlikelystudio/react-slider'

import { ArrowLeftIcon, ArrowRightIcon } from '~/components/Abstracts/Icons'
import SliderControls from '~/components/Abstracts/SliderControls'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ProductSliderProps = SliderProps & {
  className?: string
  sliderClassName?: string
  controlsClassName?: string
}

function ProductSlider(
  {
    className,
    children,
    controlsClassName,
    sliderClassName,
    infinite = true,
    ...props
  }: ProductSliderProps,
  ref: any,
) {
  const [{ slideIndex, prevSlide, nextSlide }, setSliderState] =
    useSliderState()
  const displayPrevArrow = infinite || slideIndex > 0
  const displayNextArrow = infinite || slideIndex < children?.length - 1
  return (
    <div className={cx(className, css.ProductSlider)}>
      {children?.length > 1 && (
        <>
          <SliderControls
            className={cx(css.sliderControls, controlsClassName)}
            buttonClassName={css.sliderButton}
            activeClassName={cx({ isActive: true })}
            length={children?.length}
            slideIndex={slideIndex}
          />
          {displayPrevArrow && (
            <button
              className={cx(css.arrow, css.left)}
              onClick={(e) => {
                e?.preventDefault()
                prevSlide()
              }}>
              <ArrowLeftIcon width={10} />
            </button>
          )}
          {displayNextArrow && (
            <button
              className={cx(css.arrow, css.right)}
              onClick={(e) => {
                e?.preventDefault()
                nextSlide()
              }}>
              <ArrowRightIcon width={10} />
            </button>
          )}
        </>
      )}
      <Slider
        className={cx(css.slider, sliderClassName)}
        setSliderState={setSliderState}
        snap
        infinite
        customSliderRef={ref}
        {...props}>
        {children}
      </Slider>
    </div>
  )
}

export default forwardRef(ProductSlider)

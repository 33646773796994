import classnames from 'classnames'
import { GlobalThemeColors } from '~/@types/colors'
import { WbProductVariant } from '~/@types/product'

import { UnlikelyCartLineInput } from '@unlikelystudio/commerce-connector'
import { LinkProps } from '@unlikelystudio/react-abstract-components'

import AddToCartButton from '~/components/Abstracts/AddToCartButton'
import { ProductCardQuickBuyProps } from '~/components/UI/ProductCard/ProductCardQuickBuy'
import { OutOfStockButton } from '~/components/UI/ProductCard/ProductCardQuickBuy/OutOfStockButton'
import { PreOrderButton } from '~/components/UI/ProductCard/ProductCardQuickBuy/PreOrderButton'
import { VariantsCta } from '~/components/UI/ProductCard/ProductCardQuickBuy/VariantsCta'
import css from '~/components/UI/ProductCard/ProductCardQuickBuy/styles.module.scss'

type ProductCardQuickBuyContentProps = {
  isAvailableForSale: boolean
  outOfStockCTAText: string
  shouldEnablePreOrder: boolean
  isUniqueVariant: boolean
  onAddToCart: () => void
  lang: string
  cartItems: UnlikelyCartLineInput[]
  isPreOrder: ProductCardQuickBuyProps['isPreOrder']
  designer: ProductCardQuickBuyProps['designer']
  image: ProductCardQuickBuyProps['image']
  name: ProductCardQuickBuyProps['name']
  trackingData: ProductCardQuickBuyProps['trackingData']
  defaultVariant: ProductCardQuickBuyProps['defaultVariant']
  variantsAvailableToSell: WbProductVariant[]
  link?: LinkProps
}

const cx = classnames.bind(css)
export function ProductCardQuickBuyContent({
  isAvailableForSale,
  outOfStockCTAText,
  shouldEnablePreOrder,
  isUniqueVariant,
  onAddToCart,
  lang,
  cartItems,
  isPreOrder,
  designer,
  image,
  name,
  trackingData,
  defaultVariant,
  variantsAvailableToSell,
  link,
}: ProductCardQuickBuyContentProps) {
  return (
    <>
      {!isAvailableForSale ? (
        <OutOfStockButton
          className={cx(css.button, css.disabled)}
          link={link}
          outOfStockCTAText={outOfStockCTAText}
        />
      ) : shouldEnablePreOrder ? (
        <PreOrderButton className={cx(css.button, css.disabled)} link={link} />
      ) : isUniqueVariant ? (
        <AddToCartButton
          theme={GlobalThemeColors.Black}
          CtaProps={{
            withBackground: true,
            withHover: true,
            withBorder: false,
          }}
          className={cx(css.button, css.addToCart)}
          onClick={onAddToCart}
          cartItems={cartItems}
        />
      ) : (
        <VariantsCta
          variants={variantsAvailableToSell}
          defaultVariant={defaultVariant}
          onAddToCart={onAddToCart}
          lang={lang}
          designer={designer}
          image={image}
          name={name}
          isPreOrder={isPreOrder}
          trackingData={trackingData}
        />
      )}
    </>
  )
}

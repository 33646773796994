import classnames from 'classnames/bind'
import { useEffect, useRef, useState } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import { Link } from '@unlikelystudio/react-abstract-components'
import {
  DeviceType,
  useDeviceType,
  useIsHover,
} from '@unlikelystudio/react-hooks'

import Image, { imageLoaderStoryblok } from '~/components/UI/Image'
import ImageHover from '~/components/UI/ImageHover'
import {
  CARD_IMAGE_LOADER,
  CARD_PRODUCT_LAYOUT,
  ProductCardProps,
} from '~/components/UI/ProductCard/types'
import ProductCardTags from '~/components/UI/ProductCardTags'
import ProductSlider from '~/components/UI/ProductSlider'
import ProductTag from '~/components/UI/ProductTag'
import WishlistIcon from '~/components/UI/WishlistIcon'

import { useStyle } from '~/providers/StyleProvider'

import ProductCardQuickBuy from './ProductCardQuickBuy'
import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  [CARD_PRODUCT_LAYOUT.REGULAR]: [
    { breakpoint: 'md', ratio: 1 / 3 },
    { ratio: 1 },
  ],
  [CARD_PRODUCT_LAYOUT.SMALL_GRID]: [
    { breakpoint: 'md', ratio: 1 / 6 },
    { ratio: 1 },
  ],
  [CARD_PRODUCT_LAYOUT.MEDIUM_GRID]: [
    { breakpoint: 'md', ratio: 2 / 5 },
    { ratio: 1 / 2 },
  ],
  [CARD_PRODUCT_LAYOUT.BIG_GRID]: [
    { breakpoint: 'md', ratio: 1 / 3 },
    { ratio: 1 },
  ],
  [CARD_PRODUCT_LAYOUT.SEARCH_GRID]: [
    { breakpoint: 'md', ratio: 1 / 4 },
    { ratio: 1 / 2 },
  ],
}

function ProductCard({
  className,
  shopifyId,
  link,
  label,
  name,
  compareAtPrice,
  price,
  image,
  imageHover,
  sustainabilityTags,
  productStatusTags,
  layout = CARD_PRODUCT_LAYOUT.REGULAR,
  trackingData,
  wishlistDetails,
  priority,
  sliderItemClassName,
  isPreOrder,
  designer,
  variants,
  isAdjusting,
  adjustableOffset,
  isWishListCard,
  customTags,
  hasStockAlert,
  isSpecialOrder,
  displayWishlistIcon = true,
}: ProductCardProps) {
  const [wasHovered, setWasHovered] = useState<boolean>(false)
  const [isHovered, callbacks] = useIsHover()

  const sliderRef = useRef()
  const device = useDeviceType()
  const creatorStyle = useStyle({
    textPreset: GlobalTextPreset.Title15SangBleu,
  })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Grotesk,
  })
  const compareAtStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Grotesk,
    color: GlobalThemeColors.Gray,
  })
  const isHovering = device === DeviceType.mobile ? false : isHovered

  const wishListCardMobileStyle = isWishListCard ? css.wishListCardMobile : ''

  useEffect(() => {
    if (isHovered) {
      setWasHovered(true)
    }
  }, [isHovered])

  return (
    <Link {...link} {...callbacks} className={cx(css.ProductCard, className)}>
      {(productStatusTags || sustainabilityTags) &&
        (!wishlistDetails || wishlistDetails?.inList) && (
          <ProductCardTags
            {...{
              productStatusTags: productStatusTags ?? null,
              sustainabilityTags: sustainabilityTags ?? null,
              customTags: customTags ?? [],
            }}
          />
        )}
      <div className={cx(css.imageContainer, css.imageRatio)}>
        <div className={css.overlay}>
          {wishlistDetails && (
            <>
              <div className={css.wishlistWrapper}>
                <div className={css.widthlistDetails}>
                  {displayWishlistIcon && (
                    <WishlistIcon
                      className={cx(css.wishlist, {
                        inList: wishlistDetails?.inList,
                      })}
                      uuid={wishlistDetails?.uuid}
                      variantId={wishlistDetails?.variantId}
                      trackingData={trackingData}
                    />
                  )}

                  {!wishlistDetails?.inList && (
                    <div className={css.details}>
                      {wishlistDetails?.tags?.map((label) => (
                        <ProductTag
                          key={`product-detail-${label}`}
                          className={css.tag}
                          borderColor={GlobalThemeColors.Gray}>
                          {label}
                        </ProductTag>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {shopifyId && (
                <ProductCardQuickBuy
                  name={name}
                  image={image}
                  trackingData={trackingData}
                  display={isHovering}
                  key={shopifyId}
                  {...{
                    shopifyId,
                    designer,
                    isPreOrder,
                    variants,
                    isAdjusting,
                    adjustableOffset,
                    hasStockAlert,
                    isSpecialOrder,
                    wasHovered,
                    link,
                  }}
                />
              )}
            </>
          )}
        </div>
        <div className={cx(css.hideOnSmallScreen)}>
          {image && (
            <Image
              {...image?.data}
              className={cx(css.image)}
              draggable={false}
              sizesFromBreakpoints={IMAGE_SIZES[layout]}
              layout="fill"
              objectFit="cover"
              asPlaceholder
              priority={priority}
              loader={
                image?.loaderType === CARD_IMAGE_LOADER.STORYBLOK
                  ? imageLoaderStoryblok
                  : null
              }
            />
          )}
          {imageHover && (
            <ImageHover
              isHovering={isHovering}
              className={cx(css.hoverImage)}
              sizesFromBreakpoints={IMAGE_SIZES[layout]}
              layout="fill"
              objectFit="cover"
              draggable={false}
              loader={
                imageHover.loaderType === CARD_IMAGE_LOADER.STORYBLOK
                  ? imageLoaderStoryblok
                  : null
              }
              {...imageHover.data}
            />
          )}
        </div>
        <div className={css.hideOnLargeScreen}>
          <ProductSlider
            className={css.productSlider}
            controlsClassName={css.sliderControls}
            sliderClassName={css.slider}
            ref={sliderRef}
            infinite={false}>
            {[image, imageHover]
              ?.filter((item) => item?.data)
              ?.map((image, i) => (
                <div
                  key={`${image?.data?.src}-${i}`}
                  className={cx(
                    css.sliderItem,
                    sliderItemClassName,
                    css[layout],
                    wishListCardMobileStyle,
                  )}>
                  <div className={css.imageRatio}>
                    <Image
                      className={css.image}
                      draggable={false}
                      sizesFromBreakpoints={IMAGE_SIZES[layout]}
                      layout="fill"
                      objectFit="cover"
                      asPlaceholder
                      loader={
                        image.loaderType === CARD_IMAGE_LOADER.STORYBLOK
                          ? imageLoaderStoryblok
                          : null
                      }
                      {...image.data}
                    />
                  </div>
                </div>
              ))}
          </ProductSlider>
        </div>
      </div>
      <div className={css.bottom}>
        <div className={css.bottomLeft}>
          {label && (
            <span className={cx(css.creator, creatorStyle)}>{label}</span>
          )}
          {name && <div className={cx(css.title, titleStyle)}>{name}</div>}
        </div>
        <span className={cx(css.price, titleStyle)}>
          {compareAtPrice && (
            <del className={cx(css.compareAtPrice, compareAtStyle)}>
              {compareAtPrice}
            </del>
          )}
          {price}
        </span>
      </div>
    </Link>
  )
}

export type { ProductCardProps }

export default ProductCard

import { getIntlLocale } from '~/utils/locales'

const OPTS:Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit'
}

export default function serializeDate(date, locale, options:Intl.DateTimeFormatOptions = OPTS) {
  const intl = getIntlLocale(locale)
  return date
    ? new Intl.DateTimeFormat(intl, options).format(new Date(date))
    : null
}

import { isVercelProduction } from '~/utils/vercel-env'

// 12 hours to global data file revalidate
export const GLOBAL_DATA_FILE_REVALIDATE_TIME = 12 * 60 * 60
// 30 minutes to global data file development revalidate
export const GLOBAL_DATA_FILE_DEVELOPMENT_REVALIDATE_TIME = 30 * 60
// 1 hour to revalidate
export const REVALIDATE_TIME = 60 * 60
// 1 second to debug revalidate
export const DEBUG_REVALIDATE_TIME = 1
// 30 second to revalidate
export const DEVELOPMENT_TIME = 30
// 30 second to revalidate
export const NOT_FOUND_TIME = 30

export const getRevalidateTimeFromEnv = () =>
  isVercelProduction ? REVALIDATE_TIME : DEVELOPMENT_TIME

export const getGlobalDataRevalidateTimeFromEnv = () =>
  isVercelProduction
    ? GLOBAL_DATA_FILE_REVALIDATE_TIME
    : GLOBAL_DATA_FILE_DEVELOPMENT_REVALIDATE_TIME

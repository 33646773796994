export const SQUARE_IMAGE = {
  src: '/fake-assets/square.jpg',
  local: true,
  width: 661,
  height: 660,
  alt: null,
}

export const LANDSCAPE_IMAGE = {
  src: '/fake-assets/landscape.jpg',
  local: true,
  width: 1440,
  height: 820,
  alt: null,
}

export const PORTRAIT_IMAGE = {
  src: '/fake-assets/portrait.jpg',
  local: true,
  width: 688,
  height: 1100,
  alt: null,
}

export const PRODUCT_PORTRAIT_IMAGE = {
  src: '/fake-assets/product_portrait.jpg',
  local: true,
  width: 461,
  height: 614,
  alt: null,
}

export const PRODUCT_PORTRAIT_IMAGE_HOVER = {
  src: '/fake-assets/product_portrait_hover.jpg',
  local: true,
  width: 461,
  height: 614,
  alt: null,
}

export const PRODUCT_LANDSCAPE_IMAGE = {
  src: '/fake-assets/product_landscape.jpg',
  local: true,
  width: 808,
  height: 1076,
  alt: null,
}

export const FACEBOOK_ICON = {
  src: '/fake-assets/facebook-icon.png',
  local: true,
  width: 10,
  height: 10,
  alt: 'facebook',
}

export const SVG_IMAGE = {
  src: '/fake-assets/truck.svg',
  local: true,
  width: 14,
  height: 12,
  alt: 'truck',
}

export const LOGO_IMAGE = {
  src: '/fake-assets/logo.svg',
  local: true,
  width: 841,
  height: 1120,
  alt: null,
}

export const AUTH_IMAGE = {
  src: '/fake-assets/auth-image.jpg',
  local: true,
  width: 720,
  height: 900,
  alt: null,
}

export const DESIGNER_PORTRAIT_IMAGE_1 = {
  src: '/fake-assets/designer-portrait-1.jpg',
  local: true,
  width: 688,
  height: 1100,
  alt: null,
}

export const DESIGNER_PORTRAIT_IMAGE_2 = {
  src: '/fake-assets/designer-portrait-2.jpg',
  local: true,
  width: 454,
  height: 630,
  alt: null,
}

export const STORE_PORTRAIT_IMAGE_1 = {
  src: '/fake-assets/store-portrait-1.jpg',
  local: true,
  width: 461,
  height: 600,
  alt: null,
}

export const STORE_PORTRAIT_IMAGE_2 = {
  src: '/fake-assets/store-portrait-2.jpg',
  local: true,
  width: 461,
  height: 600,
  alt: null,
}

export const HERO_SLIDER_IMAGE_1 = {
  src: '/fake-assets/hero-slider-image-1.jpg',
  local: true,
  width: 960,
  height: 884,
  alt: null,
}

export const HERO_SLIDER_IMAGE_2 = {
  src: '/fake-assets/hero-slider-image-2.jpg',
  local: true,
  width: 960,
  height: 884,
  alt: null,
}

export const PUSH_CATEGORY_IMAGE_1 = {
  src: '/fake-assets/push-category-image-1.jpg',
  local: true,
  width: 1440,
  height: 810,
  alt: null,
}

export const PUSH_CATEGORY_IMAGE_2 = {
  src: '/fake-assets/push-category-image-2.jpg',
  local: true,
  width: 1440,
  height: 810,
  alt: null,
}

export const CARD_GENERIC_BIG_IMAGE_1 = {
  src: '/fake-assets/card_generic_big_1.jpg',
  local: true,
  width: 930,
  height: 620,
  alt: null,
}

export const CARD_GENERIC_REGULAR_IMAGE_1 = {
  src: '/fake-assets/card_generic_regular_1.jpg',
  local: true,
  width: 460,
  height: 620,
  alt: null,
}

export const PUSH_NEWSLETTER_IMAGE = {
  src: '/fake-assets/push_newsletter.jpg',
  local: true,
  width: 1440,
  height: 441,
  alt: null,
}

import React, { DOMAttributes, Fragment } from 'react'
import { UrlObject } from 'url'

import { Link } from '@unlikelystudio/react-abstract-components'

export type WrapperWithLinkProps = DOMAttributes<any> & {
  className?: string
  children: JSX.Element | JSX.Element[]
  href?: string | UrlObject
}

function WrapperWithLink({
  className,
  children,
  href,
  ...rest
}: WrapperWithLinkProps) {
  return (
    <Fragment>
      {href ? (
        <Link href={href} {...rest} className={className}>
          {children}
        </Link>
      ) : (
        <div className={className}>{children}</div>
      )}
    </Fragment>
  )
}

WrapperWithLink.defaultProps = {}

export default WrapperWithLink

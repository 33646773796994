import { useTranslate } from 'react-polyglot'

import { ProductStatusTags } from '~/components/UI/ProductCard/types'
import { PRODUCT_STATUSES } from '~/components/UI/ProductCardTags'

import getProductTimedStatus from '~/utils/get-product-timed-status'

import { PRODUCT_STATUS } from '~/data/dictionary'

export default function useProductStatusTagsValues(
  tags: ProductStatusTags,
): string[] {
  const t = useTranslate()
  const {
    isNew,
    lastBackInStockTime,
    isUniquePiece,
    firstPublishedAtTime,
    isExclusivity,
  } = tags

  const { isBackInstock } = getProductTimedStatus(
    firstPublishedAtTime,
    lastBackInStockTime,
  )

  const statusTags = {
    isNew,
    isBackInstock,
    isUniquePiece,
    isExclusivity,
  }

  return Object.entries(statusTags)
    .map(([key, value]) =>
      value ? t(PRODUCT_STATUS[PRODUCT_STATUSES[key]]) : false,
    )
    .filter((item) => item)
}

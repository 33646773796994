import { WbProductVariant } from '~/@types/product'

import {
  GID,
  UnlikelyImage,
  UnlikelyMedia,
  UnlikelyVariant,
  UnlikelyVideo,
} from '@unlikelystudio/commerce-connector'

import { keepAvailableVariants } from '~/utils/keep-available-variants'

import { serializeAdjustableVariants } from '~/data/serialize-adjustable-variants'
import serializePrice from '~/data/serialize-price'
import {
  PRODUCT_MEDIA_IMAGE,
  PRODUCT_MEDIA_VIDEO,
} from '~/data/serialize-product'
import serializeShopifyImage from '~/data/serialize-shopify-image'
import { serializeUnlikelyVariantToWbVariant } from '~/data/serialize-unlikely-variant-wb-variant'

type ShopifyProductData = {
  isAdjustable: boolean
  adjustableOffsetValue: number
  productVariants: UnlikelyVariant[]
  shopifyProductId: GID
  productImages: UnlikelyImage[]
  media: UnlikelyMedia[]
  locale: string
}

export function serializeShopifyProductData({
  isAdjustable,
  adjustableOffsetValue,
  productVariants,
  shopifyProductId,
  productImages,
  media,
  locale,
}: ShopifyProductData) {
  let variants = productVariants
    ?.map((variant) => serializeUnlikelyVariantToWbVariant(variant, locale))
    ?.filter(Boolean) as WbProductVariant[]

  const isAdjusting = isAdjustable ?? false
  const adjustableOffset = adjustableOffsetValue ?? 0

  if (isAdjusting) {
    variants = serializeAdjustableVariants(variants, adjustableOffset)
  }

  // Order variants by value
  const orderedVariants = variants?.sort()

  // set the lowest price as default variant
  const defaultVariant =
    orderedVariants?.filter((item) => item?.availableForSale)?.[0] ??
    orderedVariants?.[0] ??
    null

  // Get the cheapest variant
  const cheapestVariant = variants
    ? [...variants]?.sort((a, b) => {
        return a.unformattedPrice?.amount - b.unformattedPrice?.amount
      })?.[0]
    : null

  // Getting the price from the cheapest variant
  const { currencyCode, amount } = cheapestVariant?.unformattedPrice ?? {}
  const price = serializePrice(locale, currencyCode, amount)

  // Serializing Shopify Image
  const imageItems =
    productImages
      ?.map((item) => {
        return { video: null, image: serializeShopifyImage(item) ?? null }
      })
      ?.filter((item) => item && item?.image) ?? null

  return {
    shopifyId: shopifyProductId ?? null,
    reference: defaultVariant?.sku ?? null,
    unformattedPrice: cheapestVariant?.unformattedPrice ?? null,
    compareAtPrice: cheapestVariant?.compareAtPrice ?? null,
    price,
    variants: orderedVariants ?? [],
    defaultVariant,
    isAvailable: keepAvailableVariants(productVariants),
    items: imageItems ?? [],
    mediaItems:
      media?.map((media) => serializeMediaItems(media))?.filter(Boolean) ??
      null,
  }
}

function serializeMediaItems(media: UnlikelyMedia) {
  const type = media?.mediaContentType ?? null
  switch (type) {
    case PRODUCT_MEDIA_IMAGE: {
      const image = media as UnlikelyImage
      return { type, image: serializeShopifyImage(image), video: null }
    }
    case PRODUCT_MEDIA_VIDEO: {
      const video = media as UnlikelyVideo
      return {
        type,
        ...(video?.sources?.[0]?.url
          ? { video: { src: video?.sources?.[0]?.url } }
          : {}),
        image: null,
      }
    }
    default:
      return null
  }
}

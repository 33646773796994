import {
  SHOPIFY_PRODUCT_METAFIELDS_KEYS,
  SHOPIFY_VARIANT_METAFIELDS_KEYS,
} from '~/lib/shopify/metafields'
import { getSfProduct } from '~/lib/shopify/product/product-by-id'

import {
  LinkProps,
  ProductDataProps,
} from '@unlikelystudio/react-abstract-components'

import type { ImageProps } from '~/components/Abstracts/Image'
import type { RichTextBlock } from '~/components/Abstracts/RichText'
import { VideoProps } from '~/components/Abstracts/Video'
import { ImageVideoProps } from '~/components/UI/ImageVideo'
import type { InlineCtaProps } from '~/components/UI/InlineCta'

import {
  fromProductCardPropsToProductTrackingData,
  ProductTrackingData,
} from '~/hooks/tracking/useTrackProductCardsList'

import serializeJSONLD from '~/data/json-ld/product/serialize-json-ld'
import {
  FallbackI18nFieldsData,
  serializeFallbackI18nFields,
} from '~/data/product-page-data/serialize-fallback-i18n-fields'
import serializeLink from '~/data/serialize-link'
import { serializeStoryblokProductData } from '~/data/serialize-storyblok-product-data'
import {
  serializeStoryblokShopifyData,
  StoryblokShopifyData,
} from '~/data/serialize-storyblok-shopify-data'

export const PRODUCT_MEDIA_VIDEO = 'VIDEO'
export const PRODUCT_MEDIA_IMAGE = 'IMAGE'

export type ShopifyMediaItem = {
  type: typeof PRODUCT_MEDIA_IMAGE | typeof PRODUCT_MEDIA_VIDEO
  image?: ImageProps
  video?: VideoProps
}

export type SerializedProduct = StoryblokShopifyData & {
  slug?: string
  category?: string[]
  categories?: string[]
  cardImage?: ImageProps
  variantsItems?: ImageVideoProps[]
  breathImages?: ImageVideoProps[]
  productName?: string
  productDesigner?: LinkProps
  link?: InlineCtaProps
  subtitle?: RichTextBlock
  text?: RichTextBlock
  i18nFallbackFields?: FallbackI18nFieldsData
  trackingData?: ProductTrackingData
  jsonLD?: ProductDataProps
  isPreOrder?: boolean
  isAdjusting?: boolean
  adjustableOffset?: number
}

export default async function serializeProduct({
  locale,
  data,
}): Promise<SerializedProduct> {
  const storyblokContent = await serializeStoryblokProductData({ locale, data })

  // Checking if the data from the new integration field is filled
  const hasV2ShopifyData = data?.content?.shopify_product_v2 ? true : false

  // Getting the data depending of which field is filled
  // And handling the fallbacking on the old fields if not filled
  const shopifyProductFromStoryblok = hasV2ShopifyData
    ? data?.content?.shopify_product_v2
    : data?.content?.shopify_product ?? null

  if (!shopifyProductFromStoryblok) return null

  // Getting the ID from the Storyblok content or the integration field
  const shopifyProductId = hasV2ShopifyData
    ? shopifyProductFromStoryblok?.data?.id
    : shopifyProductFromStoryblok?.content?.shopify_id ?? null

  if (!shopifyProductId) return null

  // Querying SF
  const sfProduct = await getSfProduct({
    id: shopifyProductId,
    includeFeaturedImage: true,
    storeAvailabilityFirst: 100,
    imagesFirst: 50,
    metafieldKeys: SHOPIFY_PRODUCT_METAFIELDS_KEYS,
    metafieldVariantsKeys: SHOPIFY_VARIANT_METAFIELDS_KEYS,
    locale,
  })

  if (!sfProduct) return null

  // Serializing the Shopify data from the SF one
  const serializedShopifyProduct =
    serializeStoryblokShopifyData({
      shopifyProduct: sfProduct,
      storyblokContent,
      locale,
    }) ?? null

  // Fallbacking the fields with the two datas from the CMS and the Shopify product
  const i18nFallbackFields = serializeFallbackI18nFields({
    shopifyProduct: sfProduct,
    rawStoryblokData: data,
    locale,
  })

  // And we're setting everything up
  const price = +serializedShopifyProduct?.unformattedPrice?.amount
  const trackingData = fromProductCardPropsToProductTrackingData(
    {
      name: i18nFallbackFields?.productName ?? null,
      designer: (storyblokContent?.productDesigner?.children as string) ?? '',
      shopifyId: serializedShopifyProduct?.shopifyId ?? null,
      categories: storyblokContent?.trackingCategories ?? null,
      variantId: serializedShopifyProduct?.variants?.[0]?.id ?? null,
      sku: serializedShopifyProduct?.variants?.[0]?.sku ?? '',
      size: serializedShopifyProduct?.variants?.[0]?.title ?? null,
      priceAmount: !isNaN(price) ? price : null,
    },
    {},
  )

  return {
    ...(storyblokContent ?? {}),
    ...(serializedShopifyProduct ?? {}),
    productName: i18nFallbackFields?.productName ?? null,
    i18nFallbackFields,
    trackingData,
    jsonLD: serializeJSONLD(
      storyblokContent,
      serializedShopifyProduct,
      serializeLink(data, locale)?.href,
      i18nFallbackFields?.productName ?? data?.name ?? null,
      i18nFallbackFields?.productDescription ??
        data?.product_description ??
        data?.name ??
        null,
    ),
  }
}

import { CardProduct } from '~/@types/card'
import { WbMoney, WbProductVariant } from '~/@types/product'

import type { ImageProps } from '~/components/UI/Image'
import type { WishlistIconProps } from '~/components/UI/WishlistIcon'

import type { ProductTrackingData } from '~/hooks/tracking/useTrackProductCardsList'

export const CARD_PRODUCT_LAYOUT = {
  REGULAR: 'regular',
  SMALL_GRID: 'small_grid',
  MEDIUM_GRID: 'medium_grid',
  BIG_GRID: 'big_grid',
  SEARCH_GRID: 'search_grid',
} as const

export type CardProductLayoutType =
  typeof CARD_PRODUCT_LAYOUT[keyof typeof CARD_PRODUCT_LAYOUT]

export const CARD_IMAGE_LOADER = {
  STORYBLOK: 'storyblok',
  SHOPIFY: 'shopify',
} as const

export type CardImageLoader =
  typeof CARD_IMAGE_LOADER[keyof typeof CARD_IMAGE_LOADER]

export type CardImage = {
  loaderType: CardImageLoader
  data: ImageProps
}

export type ProductCardProps = Omit<
  CardProduct,
  'name' | 'image' | 'imageHover'
> & {
  className?: string

  sliderItemClassName?: string
  label?: string
  name?: string
  designer?: string
  variation?: string
  sustainabilityTags?: string[]
  productStatusTags?: ProductStatusTags
  unformattedPrice?: WbMoney
  trackingData?: ProductTrackingData
  breathImages?: ImageProps[]
  layout?: CardProductLayoutType
  wishlistDetails?: Omit<WishlistIconProps, 'trackingData'> & {
    tags?: string[]
    inList?: boolean
  }
  image?: CardImage
  imageHover?: CardImage
  priority?: boolean
  isPreOrder?: boolean
  hasStockAlert?: boolean
  isSpecialOrder?: boolean
  variants?: WbProductVariant[]
  isAdjusting?: boolean
  adjustableOffset?: number
  isWishListCard?: boolean
  customTags?: string[]
  displayWishlistIcon?: boolean
}

export type ProductStatusTags = {
  isExclusivity?: boolean
  isUniquePiece?: boolean
  isNew?: boolean
  lastBackInStockTime?: number
  firstPublishedAtTime?: number
}

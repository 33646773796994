import { ISbResult } from 'storyblok-js-client'
import { PRODUCT_CATEGORIES } from '~/lib/fetch-links'
import Storyblok, { pageSbParams } from '~/lib/storyblok'
import { STORYBLOK_SUBTYPES, STORYBLOK_TYPES } from '~/lib/storyblok-types'

import { ProductHeaderProps } from '~/components/UI/ProductHeader'

import { getLang } from '~/utils/locales'
import { removeTrailingSlash } from '~/utils/trailing-slash'

import { DefaultPageData, SerializerParams } from '~/data/page-data/serializer'
import serializeOverridenMetas from '~/data/product-page-data/serialize-overriden-metas'
import serializeProductBreadcrumb from '~/data/product-page-data/serialize-product-breadcrumb'
import serializeProductHeader from '~/data/product-page-data/serialize-product-header'
import {
  RecommendationData,
  serializeRecommendationData,
} from '~/data/product-page-data/serialize-recommended-products'
import serializeLink from '~/data/serialize-link'
import serializeProduct from '~/data/serialize-product'

function trimAndSplit(strToTrim: string, strToReplace: string) {
  return strToTrim.replace(strToReplace, '').split('/')?.filter(Boolean)
}

/**
 * Check wether a category is a sub category
 * @param category story
 * @returns boolean
 */
export function isSubCategory(category) {
  const slugBase = `${STORYBLOK_SUBTYPES.PRODUCT_ATTRIBUTES}/${STORYBLOK_SUBTYPES.PRODUCT_CATEGORIES}/`
  const fullSlug = category?.default_full_slug
  // Remove slug base from default slug (product_attributes/product_categories/) & split by '/'
  const splitted = fullSlug?.replace(slugBase, '')?.split('/')
  // If there are more than 1 chained category then this is a subcategory
  // eg: necklaces/long -> ["necklaces", "long"]
  return splitted?.filter(Boolean)?.length > 1
}

/**
 * Retrieve all chained categories from a category story
 * @param initialCategories story
 * @param locale string
 * @returns story[]
 */
export async function getCategoriesFromCategoryAttributes(
  initialCategories,
  locale: string,
) {
  // If the initial categories are not defined, return empty array
  if (!initialCategories || !initialCategories?.length) return []

  // Make sure all categories have a valid default slug
  const categories = initialCategories?.filter((c) => c?.default_full_slug)

  // If there are no categories with valid default slug, return empty array
  if (!categories || !categories?.length) return []

  const lang = getLang(locale)

  // Store slug base in const 'product_atributes/product_categories'
  const slugBase = `${STORYBLOK_SUBTYPES.PRODUCT_ATTRIBUTES}/${STORYBLOK_SUBTYPES.PRODUCT_CATEGORIES}/`

  const reconstructedSlugs = categories
    ?.filter((entry) => entry && entry?.default_full_slug)
    ?.reduce((accc, entry) => {
      /**
       * Our category attribute default_full_slug will be something like
        `/product_attributes/products_categories/necklaces/long-necklaces`
      */
      const fullSlug = removeTrailingSlash(entry.default_full_slug)

      /**
       * Split all categories from full slug
       * "product_atributes/product_categories/colliers/colliers-longs" -> ["colliers", "colliers-longs"]
       */
      const slugs = trimAndSplit(fullSlug, slugBase)
      // Loop over entries to get an array of all category & promise them all later
      const fullSlugs = slugs.reduce((acc, slug, index, array) => {
        // If the current slug is the initial category, loop over next
        if (
          slug === entry?.slug ||
          categories?.find((category) => category?.slug === slug)
        ) {
          return acc
        }

        /**
         * Get previous entries as story prefix
         * ['colliers', 'colliers-longs'] -> 'colliers/colliers-longs'
         */
        const prefix = array.slice(0, index).join('/')

        // Reconstruct full slug with prefix and slug values
        return [...acc, `${slugBase}${prefix ? `${prefix}/` : ''}${slug}`]
      }, [])

      return [...accc, ...fullSlugs]
    }, [])

  // Make sure we don't fetch the same category multiple times
  const uniqueSlugsToFetch =
    reconstructedSlugs?.filter(
      (item, index, arr) => item && arr.indexOf(item) === index,
    ) ?? []

  const fetchedCategories =
    uniqueSlugsToFetch.length > 0
      ? await Promise.all(
          uniqueSlugsToFetch?.map(async (slug) => {
            const sbParams = {
              resolve_relations: PRODUCT_CATEGORIES,
              cv: pageSbParams.cv,
              ...(pageSbParams?.instance ?? {}),
              language: lang,
            }

            const category =
              ((await Storyblok.get(
                `cdn/stories/${slug}`,
                sbParams,
              )) as ISbResult) ?? ({} as ISbResult)

            return category?.data?.story
          }),
        )
      : []

  // Concat fetched stories with original stories (already fetched in initial query)
  const categoriesArray = [...fetchedCategories, ...categories]?.sort(
    (a, b) => {
      // And sort them by hierarchy order
      // main category > ...subCategories
      const trimmedSlugA = trimAndSplit(a?.default_full_slug, slugBase)
      const trimmedSlugB = trimAndSplit(b?.default_full_slug, slugBase)
      return trimmedSlugA?.length - trimmedSlugB?.length
    },
  )

  return categoriesArray
}

export type ProductPageData = DefaultPageData & {
  currentProduct?: {
    uid?: string
    locale?: string
    shopifyProductId?: string
  }
  productHeader: ProductHeaderProps
  recommendationData?: RecommendationData
}

export async function serializeProductPage(
  primaryPageData,
  serializedPageData,
  { type, uid, locale }: SerializerParams,
): Promise<ProductPageData> {
  const serializedProduct =
    (await serializeProduct({
      locale,
      data: primaryPageData,
    })) ?? null

  const productHeader = {
    ...(await serializeProductHeader({
      locale,
      primaryPageData,
      i18nFallbackFields: serializedProduct?.i18nFallbackFields ?? {},
    })),
    ...serializedProduct,
  } as ProductHeaderProps

  const overridenMetas = serializeOverridenMetas(
    serializedPageData?.metas,
    productHeader,
  )

  const recommendationData =
    serializeRecommendationData(
      primaryPageData,
      productHeader?.productVariations ?? null,
    ) ?? null

  // We use this one to query the attribute linked to our current page
  const filterQuery = {}
  filterQuery[`relation`] = {
    in: primaryPageData?.content?.designer?.uuid,
  }
  const sbParams = {
    content_type: STORYBLOK_TYPES.DESIGNER,
    filter_query: filterQuery,
    cv: pageSbParams.cv,
    ...(pageSbParams?.instance ?? {}),
  }

  const linkedDesigner = ((await Storyblok.get(`cdn/stories`, sbParams)) ??
    {}) as ISbResult

  const linkedDesignerStory = linkedDesigner?.data?.stories?.[0] ?? null

  return {
    ...serializedPageData,
    metas: overridenMetas,
    breadcrumb: serializeProductBreadcrumb({
      pageData: primaryPageData,
      categories: serializedProduct?.categories ?? [],
      locale,
    }),
    currentProduct: {
      locale,
      uid,
    },
    recommendationData,
    productHeader: {
      ...(productHeader ? productHeader : null),
      productDesigner: {
        ...serializeLink(linkedDesignerStory, locale),
        children: primaryPageData?.content?.designer?.content?.title ?? null,
      },
      uuid: primaryPageData?.uuid ?? null,
      type,
      stockAlertProps: {
        productName: serializedProduct?.productName ?? null,
        productImage: serializedProduct?.cardImage?.src ?? null,
        redirectLink: serializedProduct?.link?.href
          ? `${process.env.NEXT_PUBLIC_VERCEL_URL}${serializedProduct.link.href}`
          : null,
      },
    },
  }
}

import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import {
  requestNextApiEndpoint,
  UNLIKELY_WISHLIST_DELETE_PARAMS,
} from '~/lib/handle-api'

import { useCustomerContext } from '@unlikelystudio/react-ecommerce-hooks'

import { GetWishlistMutationResponse } from '~/hooks/account/wishlist/useGetWishlist'
import useGetWishlistQueryKey from '~/hooks/account/wishlist/useGetWishlistQueryKey'
import useWishlistId from '~/hooks/account/wishlist/useWishlistId'
import useLocale from '~/hooks/useLocale'
import useOnMutationError from '~/hooks/useOnMutationError'

export default function useRemoveFromWishlist(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const wishlistQueryKeys = useGetWishlistQueryKey()
  const wishlistId = useWishlistId()
  const queryClient = useQueryClient()
  const onMutationError = useOnMutationError(setError)
  const { customerAccessToken } = useCustomerContext()
  const locale = useLocale()

  return useMutation<any, any, any>(
    async ({ id }) => {
      if (!customerAccessToken) return null

      return await requestNextApiEndpoint(UNLIKELY_WISHLIST_DELETE_PARAMS, {
        accessToken: customerAccessToken,
        payload: {
          locale,
          wishlistId,
          wishlistProductId: id,
        },
      })
    },
    {
      ...mutationOptions,
      onMutate: async ({ id }) => {
        await queryClient.cancelQueries(wishlistQueryKeys)

        const previousWishlist = queryClient.getQueryData(
          wishlistQueryKeys,
        ) as GetWishlistMutationResponse

        queryClient.setQueryData(
          wishlistQueryKeys,
          (previousWishlist: GetWishlistMutationResponse) => {
            const optimisticWishlist = { ...previousWishlist }

            if (optimisticWishlist?.data?.products) {
              optimisticWishlist.data.products =
                optimisticWishlist?.products?.filter(
                  (product) => product?.id !== id,
                )
            }

            return optimisticWishlist
          },
        )

        return { previousWishlist }
      },
      onSuccess: async (data, ...rest) => {
        const error = data?.errors?.[0]
        const errorPayload = { payload: [data?.errors?.[0]] }

        if (error) {
          return onMutationError(errorPayload, rest, mutationOptions?.onError)
        }

        mutationOptions?.onSuccess(data, ...rest)
      },
      onSettled: () => queryClient.invalidateQueries(wishlistQueryKeys),
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}

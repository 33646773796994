/**
 * It checks if there are any sellable variants within a given range of the current variant
 * @param currentVariant - The current variant that is being displayed
 * @param allVariants - An array of all variants for the current product
 * @param currentIndex - The index of the current variant in the array of all variants
 * @param offset - The number of variants to check on either side of the current variant.
 * @returns A boolean value.
 */
export default function lookupAdjustableVariants(
  currentVariant,
  allVariants,
  currentIndex,
  offset,
) {
  const minVariantsIndex = 0
  const maxVariantsIndex = allVariants?.length - 1
  let boundLeftOffset = currentIndex - offset
  let boundRightOffset = currentIndex + offset

  let notSellableVariantCount = 0
  let foundVariantToSell = null

  if (boundLeftOffset < minVariantsIndex) {
    boundLeftOffset = minVariantsIndex
  }

  if (boundRightOffset > maxVariantsIndex) {
    boundRightOffset = maxVariantsIndex
  }

  const entriesToCheck = boundRightOffset - boundLeftOffset

  for (let i = boundLeftOffset; i <= boundRightOffset; i++) {
    const currentLookupVariant = allVariants?.[i]

    if (currentLookupVariant?.id !== currentVariant?.id) {
      if (currentLookupVariant?.availableForSale && !foundVariantToSell) {
        foundVariantToSell = currentLookupVariant
        notSellableVariantCount = notSellableVariantCount + 1
      }
    }
  }

  return {
    isVariantSellable:
      notSellableVariantCount !== entriesToCheck && foundVariantToSell !== null,
    variantToSell: foundVariantToSell,
  }
}

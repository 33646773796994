import { useRouter } from 'next/router'
import { useTranslate } from 'react-polyglot'

import { DisabledButtonProps } from '~/components/UI/ProductCard/ProductCardQuickBuy'
import SquaredCta from '~/components/UI/SquaredCta'

import { CTA } from '~/data/dictionary'

type PreOrderButtonProps = DisabledButtonProps

export function PreOrderButton({ className, link }: PreOrderButtonProps) {
  const t = useTranslate()
  const router = useRouter()

  return (
    <SquaredCta
      className={className}
      withBackground
      withHover={false}
      onClick={() => link?.href && router.push(link?.href)}>
      {t(CTA.PRE_ORDER)}
    </SquaredCta>
  )
}
